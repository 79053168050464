import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const PuneDivine = () => {
  return (
    <>
      {" "}
      <Navbar />
      <Sidebar />
      <main>
        <div
          class="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/login.jpg")' }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
                Pune Divine
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
            <div className="mblog_info mblog_details">
              <div className="medical_box mb-40 mt-20">
                <div className="row ">
                  <div className="col-md-5 col-lg-5">
                    <img
                      src="assets/img/pue_devi.png"
                      className="img-fluid border_img"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-1 right_content"></div>
                  <div className="col-md-7 col-lg-6 right_content">
                    <div className="row ">
                      <div className="col-md-6 hhh">Team:</div>
                      <div className="col-md-6 ">Pune Divine</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Custodian:</div>
                      <div className="col-md-6 ">
                        Indian Professional Karate Council
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Status:</div>
                      <div className="col-md-6 ">
                        Champion Team of UKL Season 2.0
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Valuation:</div>
                      <div className="col-md-6 ">INR 25,00,00,000</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Team CEO:</div>
                      <div className="col-md-6 ">Vijay Chavan</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Team Manager:</div>
                      <div className="col-md-6 ">Pranav Prasun</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Team Members:</div>
                      <div className="col-md-6 ">
                        <ul>
                          <li>Mohit Tiwari (Captain) India</li>
                          <li>Hunan Avetsiyan (Armenia)</li>
                          <li>Alicja Zalecinska (Poland) </li>
                          <li>Shashank Kumar (India)</li>
                          <li>Alok Xess (India)</li>
                          <li>Sid Willington (India)</li>
                          <li>Abhishek Sisodiya (India)</li>
                        </ul>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh"></div>
                      <div className="col-md-6 "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PuneDivine;
