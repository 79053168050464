import React, { useState, useEffect } from "react";
import Navbar1 from "../Common/Navbar1";
import Footer1 from "../Common/Footer1";
import Sidebar1 from "../Common/Sidebar1";
import Moment from "moment";
import axios from "axios";
import { CountryDropdown } from "react-country-region-selector";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Kyc = () => {
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [DOB, setDOB] = useState();
  const [Line1, setLine1] = useState("");
  const [Line2, setLine2] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zipcode, setZipcode] = useState("");
  const [Country, setCountry] = useState("");
  const [Phone, setPhone] = useState("");
  const [pCode, setPCode] = useState(+91);
  const [docName, setDocName] = useState("");
  const [docNum, setDocNum] = useState("");
  const [fImage, setFImage] = useState(false);
  // status
  const [piStatus, setPiStatus] = useState("");
  const [resStatus, setResStatus] = useState("");
  const [mobStatus, setMobStatus] = useState("");
  const [docStatus, setDocStatus] = useState("");
  const [imgStatus, setImgStatus] = useState("");
  const [panImageStatus, setPanImageStatus] = useState("");
  const [selfieImageStatus, setSelfieImageStatus] = useState("");
  const [panStatus, setPanStatus] = useState("3");
  const [kycStatus, setKycStatus] = useState("");
  const [msgStatus, setMsgStatus] = useState("");

  const [bImage, setBImage] = useState(false);
  const [userUploadFImage, setUserUploadFImage] = useState(
    "/assets/img/icon2.png"
  );
  const [userUploadBImage, setUserUploadBImage] = useState(
    "/assets/img/icon2.png"
  );
  const [panNum, setPanNum] = useState("");
  const [panImage, setPanImage] = useState("");
  const [userUploadPImage, setUserUploadPImage] = useState(
    "/assets/img/icon2.png"
  );
  const [selfieImage, setSelfieImage] = useState("");
  const [userUploadSImage, setUserUploadSImage] = useState(
    "/assets/img/icon2.png"
  );
  // const [fImageRejectReason, setFImageRejectReason] = useState("");
  const [bImageRejectReason, setBImageRejectReason] = useState("");
  const [panRejectReason, setPanRejectReason] = useState("");
  const [selfieRejectReason, setSelfieRejectReason] = useState("");

  const [kycMessage, setKycMessage] = useState("");

  // disable //
  const [btnPI, setBtnPI] = useState(false);
  const [btnRes, setBtnRes] = useState(false);
  const [btnPhone, setBtnPhone] = useState(false);
  const [btnDocNum, setBtnDocNum] = useState(false);
  const [btnDocSave, setBtnDocSave] = useState(false);
  const [btnPanNum, setBtnPanNum] = useState(false);
  const [btnPanSave, setBtnPanSave] = useState(false);
  const [btnSelfie, setBtnSelfie] = useState(false);

  // errors //

  const [ErrorFName, setErrorFName] = useState("");
  const [ErrorDOB, setErrorDOB] = useState("");
  const [ErrorLine1, setErrorLine1] = useState("");
  const [ErrorCity, setErrorCity] = useState("");
  const [ErrorZipcode, setErrorZipcode] = useState("");
  const [ErrorState, setErrorState] = useState("");
  const [ErrorResCountry, setErrorResCountry] = useState("");
  const [ErrorPhone, setErrorPhone] = useState("");
  const [errorDoc, setErrorDoc] = useState("");
  const [errorFImage, setErrorFImage] = useState("");
  const [errorBImage, setErrorBImage] = useState("");
  const [errorPan, setErrorPan] = useState("");
  const [ErrorPanImage, setErrorPanImage] = useState("");
  const [errorSImage, setErrorSImage] = useState("");

  const dPic = "/assets/img/icon2.png";

  // useEffect //
  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getUser();
  }, []);

  const getUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getUser", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        // Selfie Image //
        if (
          respp.data[0].selfie === null ||
          typeof respp.data[0].selfie == "undefined"
        ) {
          var ImageUrl = "/assets/img/icon2.png";
        } else {
          var ImageUrl = baseUrl + "/static/upload/" + respp.data[0].selfie;
        }

        // Front Image //
        if (
          respp.data[0].fImage === null ||
          typeof respp.data[0].fImage == "undefined"
        ) {
          var ImageUrl2 = "/assets/img/icon2.png";
        } else {
          var ImageUrl2 = baseUrl + "/static/upload/" + respp.data[0].fImage;
        }
        if (
          respp.data[0].bImage === null ||
          typeof respp.data[0].bImage == "undefined"
        ) {
          var ImageUrl3 = "/assets/img/icon2.png";
        } else {
          var ImageUrl3 = baseUrl + "/static/upload/" + respp.data[0].bImage;
        }

        // Pancard Image //
        if (
          respp.data[0].panImage === null ||
          respp.data[0].panImage == "undefined"
        ) {
          var ImageUrl4 = "/assets/img/icon2.png";
        } else {
          var ImageUrl4 = baseUrl + "/static/upload/" + respp.data[0].panImage;
        }

        //                     respp.data[0].

        setFName(respp.data[0].fname);
        setLName(respp.data[0].lname);
        setDOB(respp.data[0].dob);
        setLine1(respp.data[0].line1);
        setLine2(respp.data[0].line2);
        setCity(respp.data[0].city);
        setState(respp.data[0].state);
        setZipcode(respp.data[0].zipcode);
        setCountry(respp.data[0].res_country);
        setPhone(respp.data[0].number);
        setDocName(respp.data[0].docType);
        setDocNum(respp.data[0].document_number);
        setPanNum(respp.data[0].pan_number);
        setUserUploadFImage(ImageUrl2);
        setUserUploadBImage(ImageUrl3);
        setUserUploadPImage(ImageUrl4);
        setUserUploadSImage(ImageUrl);
        setPiStatus(respp.data[0].pi_status);
        setResStatus(respp.data[0].res_status);
        setMobStatus(respp.data[0].m_status);
        setPanStatus(respp.data[0].pan_status);
        setImgStatus(respp.data[0].bimage_status);
        setPanImageStatus(respp.data[0].pan_image_status);
        setSelfieImageStatus(respp.data[0].selfie_status);
        setKycStatus(respp.data[0].kyc_status);
        setDocStatus(respp.data[0].doc_status);

        // setFImageRejectReason(respp.data[0].fimage_reject_reason);
        setBImageRejectReason(respp.data[0].bimage_reject_reason);
        setPanRejectReason(respp.data[0].pan_image_reject_reason);
        setSelfieRejectReason(respp.data[0].selfie_reject_reason);

        if (respp.data[0].lname === "null" || respp.data[0].lname === null) {
          setLName("");
        }
        if (respp.data[0].line2 === "null" || respp.data[0].lname === null) {
          setLName("");
        }
        if (respp.data[0].pi_status === "1") {
          setBtnPI(true);
        }
        if (respp.data[0].res_status === "1") {
          setBtnRes(true);
        }
        if (respp.data[0].m_status === "1") {
          setBtnPhone(true);
        }
        if (
          respp.data[0].doc_status === "1" ||
          respp.data[0].doc_status === "2"
        ) {
          setBtnDocNum(true);
        }
        if (
          (respp.data[0].fimage_status === "1" ||
            respp.data[0].fimage_status === "2") &&
          (respp.data[0].bimage_status === "1" ||
            respp.data[0].bimage_status === "2")
        ) {
          setBtnDocSave(true);
        }
        if (
          respp.data[0].pan_status === "1" ||
          respp.data[0].pan_status === "2"
        ) {
          setBtnPanNum(true);
        }
        if (
          respp.data[0].pan_image_status === "1" ||
          respp.data[0].pan_image_status === "2"
        ) {
          setBtnPanSave(true);
        }
        if (
          respp.data[0].selfie_status === "1" ||
          respp.data[0].selfie_status === "2"
        ) {
          setBtnSelfie(true);
        }

        // respp.data[0].pi_status === "1" &&
        // respp.data[0].res_status === "1" &&
        // respp.data[0].m_status === "1" &&

        if (
          respp.data[0].doc_status === "1" ||
          respp.data[0].bimage_status === "1" ||
          respp.data[0].fimage_status === "1" ||
          respp.data[0].pan_image_status === "1" ||
          respp.data[0].selfie_status === "1"
        ) {
          setKycMessage("Your Kyc is in verification process");
          setMsgStatus("0");
          return false;
        }

        if (respp.data[0].kyc_status === "1") {
          setMsgStatus("1");
          setKycMessage("Congratulations your Kyc is approved");
        }
      }
    });
  };

  // OnChange //
  // Personal Information //
  const onChangeFName = (event) => {
    var fName = event;
    if (!fName) {
      setErrorFName("Please enter first name");
      setFName(fName);
      return false;
    }
    var regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    if (!fName.match(regex)) {
      setErrorFName("Please enter a valid input");
      setFName(fName);
      return false;
    } else {
      setErrorFName("");
    }
    setFName(fName);
    setErrorFName("");
  };

  const onChangeLName = (event) => {
    var lName = event;
    setLName(lName);
  };

  const handleChangeDob = (event) => {
    let value = event.target.value;
    var years = Moment().diff(value, "years");
    if (!value) {
      setErrorDOB("Please enter DOB");
      setDOB(value);
      return false;
    }
    if (years < 18) {
      setDOB("");
      setErrorDOB("Age must be at least 18 years old");
      return false;
    }
    setDOB(value);
    setErrorDOB("");
  };

  // const handleChangeDob = (event) => {
  //   let value = event.target.value;
  //   var years = Moment().diff(value, "years");
  //   var messageDobErr = "";
  //   if (!value) {
  //     messageDobErr = "Please enter DOB";
  //     setErrorDOB(messageDobErr);
  //     setDOB(value);
  //     return;
  //   }
  //   if (years < 18) {
  //     messageDobErr = "Age must be at least 18 years old";
  //     setErrorDOB(messageDobErr);
  //     return false;
  //   }
  //   setDOB(value);
  //   setErrorDOB(messageDobErr);
  // };

  const submitPI = (event) => {
    event.preventDefault();
    if (!FName && !DOB) {
      setErrorFName("Please enter first name");
      setErrorDOB("Please enter DOB");
      return false;
    }
    if (!FName || FName === undefined || FName == null) {
      setErrorFName("Please enter first name");
      return false;
    }
    if (!DOB || DOB === undefined || DOB == null) {
      setErrorDOB("Please enter DOB");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      fname: FName,
      lname: LName,
      dob: DOB,
    };

    axios
      .post(baseUrl + "/auth/kyc/personalInformation", LoginData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          setErrorDOB("");
          getUser();
        } else {
          toast.dismiss();
          toast.success(respp.message);
        }
      });
  };

  // Residential Address //

  const handleChangeCity = async (event) => {
    let value = await event.target.value;
    if (!value) {
      setErrorCity("Please enter city");
      setCity(value);
      return false;
    }
    var regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    if (!value.match(regex)) {
      setErrorCity("Please enter valid input");
      setCity(value);
      return false;
    } else {
      setErrorCity("");
      setCity(value);
    }
    setErrorCity("");
    setCity(value);
  };

  const handleChangeStates = async (event) => {
    let value = await event.target.value;

    if (!value) {
      setErrorState("Please enter state");
      setState(value);
      return false;
    }
    var regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    if (!value.match(regex)) {
      setErrorState("Please enter valid input");
      setState(value);
      return false;
    } else {
      setErrorState("");
      setState(value);
    }
    setErrorState("");
    setState(value);
  };

  const handleChangeLine1 = async (event) => {
    let value = await event.target.value;
    if (!value) {
      setErrorLine1("Please enter line 1");
      setLine1(value);
      return false;
    }
    var regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    if (!value.match(regex)) {
      setErrorLine1("Please enter valid input");
      setLine1(value);
      return false;
    } else {
      setErrorLine1("");
      setLine1(value);
    }
    setErrorLine1("");
    setLine1(value);
  };

  const handleChangeLine2 = async (event) => {
    let value = await event.target.value;
    setLine2(value);
  };

  const handleChangeZip = async (event) => {
    let value = await event.target.value.trim();
    if (!value) {
      setErrorZipcode("Please enter zipcode");
      setZipcode(value);
      return false;
    }
    var regexNumber = "^[0-9]*$";
    if (!value.match(regexNumber)) {
      setErrorZipcode("Please enter only numeric value");
      setZipcode(value);
      return false;
    }
    if (value.length < 5) {
      setErrorZipcode("Please enter minimum 5 digits");
      setZipcode(value);
      return false;
    }
    if (value.length > 10) {
      setErrorZipcode("Please enter valid zipcode 5-10 digits");
      setZipcode(value);
      return false;
    }
    var regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    if (!value.match(regex)) {
      setErrorZipcode("Please enter valid input");
      setZipcode(value);
      return false;
    }
    if (value <= 0) {
      setErrorZipcode("Please enter valid input");
      setZipcode(value);
      return false;
    } else {
      setErrorZipcode("");
      setZipcode(value);
    }
    setErrorZipcode("");
    setZipcode(value);
  };

  const selectResidetialCountry = (val) => {
    setCountry(val);
    if (!val) {
      setErrorResCountry("Please select country");
      return false;
    } else {
      setErrorResCountry("");
      setCountry(val);
    }
  };

  const submitResAdd = (e) => {
    e.preventDefault();
    var regex = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    var regexNumber = "^[0-9]*$";

    if (!Line1 && !City && !State && !Country && !Zipcode) {
      setErrorLine1("Please enter line 1");
      setErrorCity("Please enter city");
      setErrorState("Please enter state");
      setErrorResCountry("Please select country");
      setErrorZipcode("Please enter zipcode");
      return false;
    }

    if (!Line1 || Line1 === undefined || Line1 == null) {
      setErrorLine1("Please enter line 1");
      return false;
    }
    if (!City || City === undefined || City == null) {
      setErrorCity("Please enter city");
      return false;
    }
    if (!State || State === undefined || State == null) {
      setErrorState("Please enter state");
      return false;
    }
    if (!Country || Country === undefined || Country == null) {
      setErrorResCountry("Please select country");
      return false;
    }
    if (!Zipcode || Zipcode === undefined || Zipcode == null) {
      setErrorZipcode("Please enter zipcode");
      return false;
    }

    if (!Zipcode.match(regexNumber)) {
      setErrorZipcode("Please enter only numeric value");
      setZipcode(Zipcode);
      return false;
    }
    if (Zipcode.length < 5) {
      setErrorZipcode("Please enter minimum 5 digits");
      setZipcode(Zipcode);
      return false;
    }
    if (Zipcode.length > 10) {
      setErrorZipcode("Please enter valid zipcode 5-10 digits");
      setZipcode(Zipcode);
      return false;
    }
    if (!Zipcode.match(regex)) {
      setErrorZipcode("Please enter valid input");
      setZipcode(Zipcode);
      return false;
    }

    if (!Line1.match(regex)) {
      setErrorLine1("Please enter valid input");
      return false;
    }
    if (!City.match(regex)) {
      setErrorCity("Please enter valid input");
      return false;
    }
    if (!State.match(regex)) {
      setErrorState("Please enter valid input");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      line1: Line1,
      line2: Line2,
      city: City,
      states: State,
      r_country: Country,
      zipcode: Zipcode,
    };
    axios
      .post(baseUrl + "/auth/kyc/residentialInformation", LoginData, config)
      .then((resp) => {
        var respp = resp.data;
        // this.setState({ BtnDisableAddressInfo: true });
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          getUser();
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  // Mobile Number //

  const handleChangePNumber = async (event) => {
    let value = await event.target.value.trim();
    if (!value) {
      setErrorPhone("Please enter mobile number");
      setPhone(value);
      return false;
    }
    if (value.length > 10) {
      setErrorPhone("Mobile number must be 10 digits");
      setPhone(value);
      return false;
    } else if (value.length < 10) {
      setErrorPhone("Mobile number must be 10 digits");
      setPhone(value);
    }
    var regexNum = "^[0-9]*$";
    if (!value.match(regexNum)) {
      setErrorPhone("Please enter only numeric value");
      setPhone(value);
      return false;
    }
    setErrorPhone("");
    setPhone(value);
  };

  const submitPhone = (event) => {
    event.preventDefault();
    var regexNum = "^[0-9]*$";

    if (!Phone) {
      setErrorPhone("Please enter mobile number");
      return false;
    }
    if (Phone.length > 10) {
      setErrorPhone("Mobile number must be 10 digits");
      return false;
    } else if (Phone.length < 10) {
      setErrorPhone("Mobile number must be 10 digits");
      return false;
    }
    if (!Phone.match(regexNum)) {
      setErrorPhone("Please enter only numeric value");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      code: pCode,
      number: Phone,
    };
    axios
      .post(baseUrl + "/auth/kyc/mobileInformation", LoginData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          getUser();
          toast.dismiss();
          toast.success(respp.message);
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  // National ID //
  const handleChangeDoc = (e) => {
    var { name, value } = e.target;
    if (name === "docName") {
      setDocName(value);
      setDocNum("");
      setErrorDoc("");
    }
    setDocName(value);
  };

  const handleChangeDocNum = (e) => {
    let value = e.target.value;
    setDocNum(value);
    var regexNumberAlpha = /[^a-z A-Z 0-9]+/g;
    if (docName === "") {
      setErrorDoc("Please select document type");
      return false;
    }
    if (docName === "aadhaar") {
      if (!value) {
        setErrorDoc("Please enter aadhaar number");
        return false;
      }
      var regexNumber = "^[0-9]*$";
      if (!value.match(regexNumber)) {
        setErrorDoc("Please enter a valid aadhaar number");
        setDocNum(value);
        return false;
      } else if (value.length < 12) {
        setErrorDoc("Please enter a valid aadhaar number");
        setDocNum(value);
      } else if (value.length > 12) {
        setErrorDoc("Please enter a valid aadhaar number");
        setDocNum(value);
      } else {
        setErrorDoc("");
        setDocNum(value);
      }
    }
    if (docName === "passport") {
      if (!value) {
        setErrorDoc("Please enter passport number");
        return false;
      }

      if (value.includes(" ")) {
        setErrorDoc("No blank space allowed");
        setDocNum(value);
        return false;
      } else {
        setErrorDoc("");
        setDocNum(value);
      }
      if (value.match(regexNumberAlpha)) {
        setErrorDoc("Please enter only alphanumeric value");
        setDocNum(value);
      } else if (value <= 0) {
        setErrorDoc("Please enter a valid  passport number");
        setDocNum(value);
      } else if (value.length < 8) {
        setErrorDoc("Please enter a valid  passport number");
        setDocNum(value);
      } else if (value.length > 9) {
        setErrorDoc("Please enter a valid  passport number");
        setDocNum(value);
      } else {
        setErrorDoc("");
        setDocNum(value);
      }
    }
    if (docName === "driving") {
      if (!value) {
        setErrorDoc("Please enter driving licence number");
        return false;
      }
      if (value.includes(" ")) {
        setErrorDoc("No blank space allowed");
        setDocNum(value);
        return false;
      } else {
        setErrorDoc("");
        setDocNum(value);
      }

      if (value.match(regexNumberAlpha)) {
        setErrorDoc("Please enter a valid  driving license number");
        setDocNum(value);
      } else if (value.length < 15) {
        setErrorDoc("Please enter a valid  driving license number");
        setDocNum(value);
      } else if (value.length > 16) {
        setErrorDoc("Please enter a valid  driving license number");
        setDocNum(value);
      } else {
        setErrorDoc("");
        setDocNum(value);
      }
    }
    if (docName === "nationalId") {
      if (!value) {
        setErrorDoc("Please enter National Id");
        setDocNum(value);
        return false;
      }
      setErrorDoc("");
      setDocNum(value);
    }
  };

  const submitDocNum = (e) => {
    if (docName === "" || docName === null) {
      setErrorDoc("Please select document type");
      return false;
    }

    if (!docNum) {
      setErrorDoc("Please enter document number");
      return false;
    }

    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      check: docName,
      number: docNum,
    };
    axios
      .post(baseUrl + "/auth/kyc/docNumber", LoginData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          getUser();
          setErrorDoc("");
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  const handleChangeFImage = (e) => {
    e.preventDefault();
    setFImage("/assets/img/icon2.png");

    var IdentityPic = e.target.files[0];
    setFImage(e.target.files[0]);

    setUserUploadFImage(URL.createObjectURL(IdentityPic));

    if (IdentityPic === false) {
      setUserUploadFImage(false);
      setErrorFImage("Please select front image");

      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png)$/)) {
      setUserUploadFImage(false);
      setErrorFImage("Please select valid image jpg|jpeg|png");

      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorFImage("Front image size should be 10KB to 1MB");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorFImage("");
    setUserUploadFImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeBImage = (e) => {
    e.preventDefault();
    setBImage("/assets/img/icon2.png");
    var IdentityPic = e.target.files[0];
    setBImage(e.target.files[0]);
    setUserUploadBImage(URL.createObjectURL(IdentityPic));
    if (IdentityPic === false) {
      setUserUploadBImage(false);
      setErrorBImage("Please select back image");
      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png)$/)) {
      setUserUploadBImage(false);
      setErrorBImage("Please select valid image jpg|jpeg|png");
      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorBImage("Back image size should be 10KB to 1MB");
      return false;
    }
    setErrorBImage("");
    setUserUploadBImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitDocImg = (e) => {
    e.preventDefault();
    var IdentityPic = fImage;
    var IdentityPic2 = bImage;

    if (docStatus !== "1") {
      setErrorDoc("Please save document number first");
      return false;
    }

    if (IdentityPic === false) {
      setErrorFImage("Please select front image");

      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrorFImage("Please select valid image jpg|jpeg|png");

      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorFImage("Front image size should be 10KB to 1MB");
      return false;
    }

    if (IdentityPic2 === false) {
      setErrorBImage("Please select back image");
      return false;
    }
    if (!IdentityPic2.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrorBImage("Please select valid image jpg|jpeg|png");
      return false;
    }
    if (IdentityPic2.size >= 1000000 || IdentityPic2.size < 10000) {
      setErrorBImage("Back image size should be 10KB to 1MB");
      return false;
    }

    const formData = new FormData();

    formData.append("IdentityProof", IdentityPic);
    formData.append("DrivingLicense", IdentityPic2);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/auth/kyc/docImage", formData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          getUser();
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  // Pancard Information //

  const handleChangePanNum = (e) => {
    let { value } = e.target;
    var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    // first 5 alpha next 4 num 1 alpha

    if (!value) {
      setErrorPan("Please enter pancard number");
      setPanNum(value);
      return false;
    }

    if (value.includes(" ")) {
      setErrorPan("No blank space allowed");
      setPanNum(value);
      return false;
    } else {
      setErrorPan("");
      setPanNum(value);
    }

    if (!reggg.test(value)) {
      setErrorPan("Please enter a valid pancard");
      setPanNum(value);
      return false;
    } else {
      setErrorPan("");
      setPanNum(value);
    }

    setPanNum(value);
    setErrorPan("");
  };

  const handleChangePImage = (e) => {
    e.preventDefault();
    setPanImage("/assets/img/icon2.png");
    var IdentityPic = e.target.files[0];
    setPanImage(e.target.files[0]);
    setUserUploadPImage(URL.createObjectURL(IdentityPic));
    if (IdentityPic === false) {
      setUserUploadPImage(false);
      setErrorPanImage("Please select pancard image");
      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png)$/)) {
      setUserUploadPImage(false);
      setErrorPanImage("Please select valid image jpg|jpeg|png");
      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorPanImage("Pancard image size should be 10KB to 1MB");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorPanImage("");
    setUserUploadPImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitPanNum = (e) => {
    e.preventDefault();
    var reggg = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!panNum) {
      setErrorPan("Please enter pancard number");
      return false;
    }

    if (panNum.includes(" ")) {
      setErrorPan("No blank space allowed");
      return false;
    }

    if (!reggg.test(panNum)) {
      setErrorPan("Please enter a valid pancard");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      panNumber: panNum,
    };
    axios
      .post(baseUrl + "/auth/kyc/panNumber", LoginData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          getUser();
          setErrorPan("");
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  const submitPanImg = (e) => {
    e.preventDefault();
    var IdentityPic = panImage;

    if (panStatus !== "1") {
      setErrorPan("Please save pancard number first");
      return false;
    }
    if (IdentityPic === false) {
      setErrorPanImage("Please select pancard image");
      return false;
    }
    if (!IdentityPic.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrorPanImage("Please select valid image jpg|jpeg|png");
      return false;
    }
    if (IdentityPic.size >= 1000000 || IdentityPic.size < 10000) {
      setErrorPanImage("Pancard image size should be 10KB to 1MB");
      return false;
    }

    const formData = new FormData();

    formData.append("PanImage", IdentityPic);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/auth/kyc/panImage", formData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          getUser();
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  // Selfie Verification //

  const handleChangeSelfie = (e) => {
    e.preventDefault();
    setSelfieImage("/assets/img/icon2.png");
    var SelfiePic = e.target.files[0];
    setSelfieImage(e.target.files[0]);
    setUserUploadSImage(URL.createObjectURL(SelfiePic));
    if (SelfiePic === false) {
      setUserUploadSImage(false);
      setErrorSImage("Please select selfie image");
      return false;
    }
    if (!SelfiePic.name.match(/\.(jpg|jpeg|png)$/)) {
      setUserUploadSImage(false);
      setErrorSImage("Please select valid image jpg|jpeg|png");
      return false;
    }
    if (SelfiePic.size >= 1000000 || SelfiePic.size < 10000) {
      setErrorSImage("Selfie image size should be 10KB to 1MB");
      return false;
    }
    // if (SelfiePic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorSImage("");
    setUserUploadSImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitSelfie = (e) => {
    e.preventDefault();
    var SelfiePic = selfieImage;
    if (SelfiePic === false) {
      setErrorSImage("Please select selfie image");
      return false;
    }
    if (!SelfiePic.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrorSImage("Please select valid image jpg|jpeg|png");
      return false;
    }
    if (SelfiePic.size >= 1000000 || SelfiePic.size < 10000) {
      setErrorSImage("Selfie image size should be 10KB to 1MB");
      return false;
    }

    const formData = new FormData();
    formData.append("SelfieImage", SelfiePic);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/auth/kyc/selfieImage", formData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          getUser();
        } else {
          toast.dismiss();
          toast.error(respp.message);
        }
      });
  };

  const imageStatusHtml = () => {
    if (imgStatus === "1") {
      return (
        <div class="alert alert-warning  alert_man">
          {" "}
          <i class="fa fa-check-circle mr-2"></i>Waiting for admin to verify
        </div>
      );
    }
    if (imgStatus === "0") {
      return (
        <div class="alert alert-danger">
          {" "}
          <i class="fa-regular fa-circle-xmark"></i>Rejected <br />
          {bImageRejectReason ? "Reason -" + bImageRejectReason : ""}
        </div>
      );
    }

    if (imgStatus === "2") {
      return (
        <div class="alert alert-success alert_man">
          <i class="fa fa-check-circle mr-2"></i>Image Information Verified{" "}
        </div>
      );
    }
  };

  const panStatusHtml = () => {
    if (panImageStatus === "1") {
      return (
        <div class="alert alert-warning  alert_man">
          {" "}
          <i class="fa fa-check-circle mr-2"></i>Waiting for admin to verify
        </div>
      );
    }
    if (panImageStatus === "0") {
      return (
        <div class="alert alert-danger">
          {" "}
          <i class="fa-regular fa-circle-xmark"></i>Rejected <br />
          {panRejectReason ? "Reason -" + panRejectReason : ""}
        </div>
      );
    }

    if (panImageStatus === "2") {
      return (
        <div class="alert alert-success alert_man">
          <i class="fa fa-check-circle mr-2"></i>Image Information Verified{" "}
        </div>
      );
    }
  };
  const selfieStatusHtml = () => {
    if (selfieImageStatus === "1") {
      return (
        <div class="alert alert-warning  alert_man">
          {" "}
          <i class="fa fa-check-circle mr-2"></i>Waiting for admin to verify
        </div>
      );
    }
    if (selfieImageStatus === "0") {
      return (
        <div class="alert alert-danger">
          {" "}
          <i class="fa-regular fa-circle-xmark"></i>Rejected <br />
          {selfieRejectReason ? "Reason -" + selfieRejectReason : ""}
        </div>
      );
    }

    if (selfieImageStatus === "2") {
      return (
        <div class="alert alert-success alert_man">
          <i class="fa fa-check-circle mr-2"></i>Image Information Verified{" "}
        </div>
      );
    }
  };

  return (
    <>
      <Navbar1 />
      {/* <Sidebar1 /> */}
      <main>
        <div class="dashbord_in">
          <div class="container custome-container">
            <h2 class="d_haddign">KYC Verification Form</h2>
            {kycStatus === "1" ? (
              <div class="alert alert-success alert_man col-md-4 ">
                <i class="fa fa-check-circle mr-2">{kycMessage}</i>
              </div>
            ) : (
              ""
            )}

            {msgStatus === "0" ? (
              <div class="alert alert-warning alert_man col-md-4 ">
                {kycMessage}
              </div>
            ) : (
              ""
            )}

            <h5 class="mt-3 mb-3 position-relative fw600">
              Personal Information
            </h5>
            <div class="white_box">
              <p>
                Fill in your personal information the way it is stated in the
                proof of identity document you submit
              </p>
              <div class="fs12">
                <form>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        name="fname"
                        placeholder="Enter First Name"
                        minlength="2"
                        maxlength="100"
                        value={FName}
                        onChange={(e) => onChangeFName(e.target.value)}
                        disabled={btnPI}
                      />
                      <div style={{ color: "red" }}>{ErrorFName}</div>
                    </div>

                    <div class="form-group col-md-6">
                      <label class="d-flex">
                        {" "}
                        Last Name
                        <span class="mla">Optional</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="lname"
                        placeholder=""
                        minlength="2"
                        maxlength="20"
                        value={LName}
                        onChange={(e) => onChangeLName(e.target.value)}
                        disabled={btnPI}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Select Date"
                        name="dob"
                        value={DOB}
                        onChange={handleChangeDob}
                        disabled={btnPI}
                      />

                      <div style={{ color: "red" }}>{ErrorDOB}</div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn_man fs12 w100px"
                    disabled={btnPI}
                    onClick={(e) => submitPI(e)}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <h5 class="mt-3 mb-3 fw600"> Residential Address</h5>
            <div class="white_box">
              <p>
                Fill in your address the way it is stated in the proof of
                residence document
              </p>
              <div class="fs12">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Line 1</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Residence Address"
                      name="Line1"
                      value={Line1}
                      onChange={(e) => handleChangeLine1(e)}
                      disabled={btnRes}
                    />
                    <div style={{ color: "red" }}>{ErrorLine1}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="d-flex">
                      Line 2 <span class="mla">Optional</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="Line2"
                      value={Line2}
                      disabled={btnRes}
                      onChange={(e) => handleChangeLine2(e)}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="d-flex">City</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter City"
                      name="City"
                      value={City}
                      disabled={btnRes}
                      onChange={(e) => handleChangeCity(e)}
                    />
                    <div style={{ color: "red" }}>{ErrorCity}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="d-flex">State</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter State"
                      name="States"
                      value={State}
                      disabled={btnRes}
                      onChange={(e) => handleChangeStates(e)}
                    />
                    <div style={{ color: "red" }}>{ErrorState}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Zip Code</label>
                    <input
                      type="text"
                      class="form-control"
                      name="Zipcode"
                      placeholder="Enter Zip Code"
                      maxlength="10"
                      pattern="[0-9]*"
                      value={Zipcode}
                      disabled={btnRes}
                      onChange={(e) => handleChangeZip(e)}
                    />
                    <div style={{ color: "red" }}>{ErrorZipcode}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Country</label>
                    <CountryDropdown
                      class="form-control"
                      name="country2"
                      value={Country}
                      onChange={(val) => selectResidetialCountry(val)}
                      disabled={btnRes}
                    />
                    <div style={{ color: "red" }}>{ErrorResCountry}</div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn_man fs12 w100px"
                  disabled={btnRes}
                  onClick={(e) => submitResAdd(e)}
                >
                  Submit
                </button>
              </div>
            </div>
            <h5 className="fw600"> Mobile Number</h5>
            <div class="white_box">
              <p>
                Fill in your mobile number details the way it is stated in the
                proof of mobile number you submit
              </p>
              <div class="row">
                <div class="form-group col-md-2">
                  <div class="country_code_outer d-flex flex-wrap">
                    <select class="form-control" disabled={btnPhone}>
                      <option value={pCode}>+91</option>
                    </select>
                  </div>
                </div>
                <div class="form-group col-6 col-md-5">
                  <input
                    type="tel"
                    autocomplete="tel"
                    placeholder="Enter mobile number"
                    name="number"
                    value={Phone}
                    maxLength="10"
                    class="form-control"
                    onChange={(e) => handleChangePNumber(e)}
                    disabled={btnPhone}
                  />
                  <div style={{ color: "red" }}>{ErrorPhone}</div>
                </div>
                <div class="col-md-2">
                  <button
                    type="submit"
                    class="btn btn_man fs14 w100px"
                    disabled={btnPhone}
                    onClick={(e) => submitPhone(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            {piStatus === "1" && resStatus === "1" && mobStatus === "1" && (
              <>
                {" "}
                <h5 class="mt-3 mb-3 fw600"> Proof Of Identity</h5>
                <div class="white_box">
                  <b class="mt-0">Requirements</b>
                  <ul class="dit_ul mb-3">
                    <li>
                      The photo/scan must be clear, in color and uncompressed.
                    </li>
                    <li>The photo/scan must be not edited and not cropped.</li>
                    <li>
                      The document must be valid at least 3 months after the
                      verification submission date.
                    </li>
                    <li>
                      The full name and date of birth you provided in the
                      "Personal information" section{" "}
                    </li>
                    <li>should exactly match the ones in your document.</li>
                    <li>Min size 10 Kb / Max size 1 Mb.</li>
                  </ul>
                  <div class="mb-2">
                    <lable>Document Type </lable>
                    <br />
                    <div class="form-check-inline ">
                      <select
                        value={docName}
                        class="form-control"
                        name="docName"
                        onChange={handleChangeDoc}
                        disabled={btnDocNum}
                      >
                        <option value="">Select Document Type</option>
                        <option value="aadhaar">Aadhaar Card</option>
                        <option value="driving">Driving Licence</option>
                        <option value="nationalId">National ID</option>
                        <option value="passport">Passport</option>
                      </select>
                    </div>
                  </div>
                  <label>Document number </label>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Document Number"
                        name="document"
                        min="10"
                        maxlength="30"
                        disabled={btnDocNum}
                        value={docNum}
                        onChange={handleChangeDocNum}
                      />
                      <div style={{ color: "red" }}>{errorDoc}</div>
                    </div>
                    <div class="form-group col-md-2">
                      <button
                        type="submit"
                        class="btn btn_man w100"
                        disabled={btnDocNum}
                        onClick={submitDocNum}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div class="fs12">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <div id="file-upload-form" class="uploader">
                          <input
                            id="file-upload"
                            type="file"
                            name="fileUpload"
                            accept="image/*"
                            onChange={handleChangeFImage}
                            disabled={btnDocSave}
                          />

                          <label for="file-upload" id="file-drag">
                            <img
                              id="file-image"
                              src={
                                userUploadFImage !== false
                                  ? userUploadFImage
                                  : dPic
                              }
                              alt="Preview"
                              // class="hidden"
                            />
                            <div id="start">
                              <span class="fasi">
                                <i class="las la-cloud-upload-alt"></i>
                              </span>
                              <div>Upload Document</div>
                              <div id="notimage" class="hidden">
                                Please select an image
                              </div>
                            </div>
                            <div id="response" class="hidden">
                              <div id="messages"></div>
                              <progress
                                class="progress"
                                id="file-progress"
                                value="0"
                              >
                                <span>0</span>%
                              </progress>
                            </div>
                          </label>
                        </div>
                        <p class="mt-0">
                          *only jpeg, jpg &amp; png files supported
                        </p>
                        <div style={{ color: "red" }}>{errorFImage}</div>
                      </div>
                      <div class="form-group col-md-4">
                        <div id="file-upload-form" class="uploader">
                          <input
                            id="file-upload2"
                            type="file"
                            name="fileUpload"
                            accept="image/*"
                            onChange={handleChangeBImage}
                            disabled={btnDocSave}
                          />

                          <label for="file-upload2" id="file-drag">
                            <img
                              id="file-image"
                              src={
                                userUploadBImage !== false
                                  ? userUploadBImage
                                  : dPic
                              }
                              alt="Preview"
                              // class="hidden"
                            />
                            <div id="start">
                              <span class="fasi">
                                <i class="las la-cloud-upload-alt"></i>
                              </span>
                              <div>Upload Document</div>
                              <div id="notimage" class="hidden">
                                Please select an image
                              </div>
                            </div>
                            <div id="response" class="hidden">
                              <div id="messages"></div>
                              <progress
                                class="progress"
                                id="file-progress"
                                value="0"
                              >
                                <span>0</span>%
                              </progress>
                            </div>
                          </label>
                        </div>
                        <p class="mt-0">
                          *only jpeg, jpg &amp; png files supported
                        </p>
                        {imageStatusHtml()}
                        <div style={{ color: "red" }}>{errorBImage}</div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn_man fs12 w100px"
                      onClick={submitDocImg}
                      disabled={btnDocSave}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {/* pancard start */}
                <h5 class="mt-3 mb-3 fw600"> Pancard Identity</h5>
                <div class="white_box">
                  <b class="mt-0">Requirements</b>
                  <ul class="dit_ul mb-3">
                    <li>
                      The photo/scan must be clear, in color and uncompressed.
                    </li>
                    <li>The photo/scan must be not edited and not cropped.</li>
                    <li>
                      The document must be valid at least 3 months after the
                      verification submission date.
                    </li>
                    <li>
                      The full name and date of birth you provided in the
                      "Personal information" section{" "}
                    </li>
                    <li>should exactly match the ones in your document.</li>
                    <li>Min size 10 Kb / Max size 1 Mb.</li>
                  </ul>
                  <label>Document number </label>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Document Number"
                        name="pancard"
                        min="10"
                        maxlength="30"
                        disabled={btnPanNum}
                        value={panNum}
                        onChange={handleChangePanNum}
                      />
                      <div style={{ color: "red" }}>{errorPan}</div>
                    </div>
                    <div class="form-group col-md-2">
                      <button
                        type="submit"
                        class="btn btn_man w100"
                        onClick={submitPanNum}
                        disabled={btnPanNum}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div class="fs12">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <div id="file-upload-form" class="uploader">
                          <input
                            id="file-uploadP"
                            type="file"
                            name="fileUpload"
                            accept="image/*"
                            onChange={handleChangePImage}
                            disabled={btnPanSave}
                          />

                          <label for="file-uploadP" id="file-drag">
                            <img
                              id="file-image"
                              src={
                                userUploadPImage !== false
                                  ? userUploadPImage
                                  : dPic
                              }
                              alt="Preview"
                              // class="hidden"
                            />
                            <div id="start">
                              <span class="fasi">
                                <i class="las la-cloud-upload-alt"></i>
                              </span>
                              <div>Upload Document</div>
                              <div id="notimage" class="hidden">
                                Please select an image
                              </div>
                            </div>
                            <div id="response" class="hidden">
                              <div id="messages"></div>
                              <progress
                                class="progress"
                                id="file-progress"
                                value="0"
                              >
                                <span>0</span>%
                              </progress>
                            </div>
                          </label>
                        </div>
                        <p class="mt-0">
                          *only jpeg, jpg &amp; png files supported
                        </p>
                        {panStatusHtml()}
                        <div style={{ color: "red" }}>{ErrorPanImage}</div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn_man fs12 w100px"
                      onClick={submitPanImg}
                      disabled={btnPanSave}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {/* pancard end */}
                <h5 class="mt-3 mb-3 fw600"> Face Verification</h5>
                <div class="white_box">
                  <b class="mt-0">Requirements</b>
                  <ul class="dit_ul mb-4">
                    <li>
                      The photo must be clear, in color, high-resolution, well
                      lit and unedited.
                    </li>
                    <li>Your face must be clearly visible.</li>
                    <li>
                      You must be holding the same document you uploaded in the
                      "Proof of Identity" section.
                    </li>
                  </ul>
                  <div class="fs12">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <div id="file-upload-form" class="uploader">
                          <input
                            id="file-uploadS"
                            type="file"
                            name="fileUpload"
                            accept="image/*"
                            onChange={handleChangeSelfie}
                            disabled={btnSelfie}
                          />

                          <label for="file-uploadS" id="file-drag">
                            <img
                              id="file-image"
                              src={
                                userUploadSImage !== false
                                  ? userUploadSImage
                                  : dPic
                              }
                              alt="Preview"
                              // class="hidden"
                            />
                            <div id="start">
                              <span class="fasi">
                                <i class="las la-cloud-upload-alt"></i>
                              </span>
                              <div>Upload Document</div>
                              <div id="notimage" class="hidden">
                                Please select an image
                              </div>
                            </div>
                            <div id="response" class="hidden">
                              <div id="messages"></div>
                              <progress
                                class="progress"
                                id="file-progress"
                                value="0"
                              >
                                <span>0</span>%
                              </progress>
                            </div>
                          </label>
                        </div>
                        <p class="mt-0">
                          *only jpeg, jpg &amp; png files supported
                        </p>
                        {selfieStatusHtml()}
                        <div style={{ color: "red" }}>{errorSImage}</div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn_man fs12 w100px"
                      onClick={submitSelfie}
                      disabled={btnSelfie}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <Footer1 />
      </main>
    </>
  );
};

export default Kyc;
