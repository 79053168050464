import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import Navbar1 from "../Common/Navbar1";
import Footer1 from "../Common/Footer1";
import Sidebar1 from "../Common/Sidebar1";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Whitelist = () => {
  const [walletAdd, setWalletAdd] = useState("");
  const [errorWalletAdd, setErrorWalletAdd] = useState("");
  const [walletTableData, setWalletTableData] = useState("");

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getUser();
    getWallet();
  }, []);

  const getUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getUser", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
      }
    });
  };

  const getWallet = (e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getwhitelist", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setWalletTableData(respp.data[0].whitelistAddress);
      }
    });
  };

  const SubmitWallet = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      walletAdd: walletAdd,
    };
    axios.post(baseUrl + "/auth/whitelist", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        setWalletAdd("");
        getWallet();
      } else {
        toast.dismiss();
        toast.error(respp.message);
      }
    });
  };

  const handleChangeWallet = (e) => {
    let { name, value } = e.target;
    if (name === "WalletAdd") {
      setWalletAdd(value);
      if (!value) {
        setErrorWalletAdd("Please enter wallet address");
        return false;
      }
      setErrorWalletAdd(" ");
    }
  };

  return (
    <>
      <Navbar1 />
      {/* <Sidebar1 /> */}
      <main>
        <div className="dashbord_in">
          <div className="container custome-container">
            <h1 class="d_haddign">Whitelist</h1>
            <div className="white_box">
              <div className="row align-items-end mb-3">
                <div className="form-group col-md-6">
                  <label className="d-flex">Wallet Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Wallet Address"
                    name="WalletAdd"
                    value={walletAdd}
                    onChange={handleChangeWallet}
                  />
                  <div style={{ color: "red" }}>{errorWalletAdd}</div>
                </div>
                <div className="form-group col-md-6">
                  <button
                    className="btn btn_man fs12 w100px"
                    onClick={SubmitWallet}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Wallet Address</th>
                    </tr>

                    <tr>
                      <td>{1}</td>
                      <td>{walletTableData}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </>
  );
};

export default Whitelist;
