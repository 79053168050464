import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";
const Training = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: 'url("assets/img/bg/login.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                TRAINING
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <h6 className="mblog__title mb-15 ">TRAINING</h6>
            <div class="row">
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="rad_box text-center">
                  <span class="icon_box">
                    <i class="fas fa-gavel"></i>
                  </span>
                  <h5>Athletes Training </h5>
                  <p>
                   Athletes training is a multifaceted
process that involves developing and
improving an athlete's physical and
mental abilities. Strength training,
cardiovascular conditioning, flexibility
training, and mental preparation are all
essential components of an athlete's
training. By incorporating these
elements into their training regimen,
athletes can improve their performance,
prevent injuries, and achieve their goals.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="rad_box text-center">
                  <span class="icon_box">
                    <i class="fas fa-gavel"></i>
                  </span>
                  <h5>Coaches Training </h5>
                  <p>
                   Coaches training is a critical process
for anyone interested in becoming a
coach or improving their coaching
skills. By developing communication,
l e a d e r s h i p , a n d e m o t i o n a l
intelligence skills, coaches can
effectively guide their athletes
towards achieving their goals and
creating a positive coaching
experience.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="rad_box text-center">
                  <span class="icon_box">
                    <i class="fas fa-gavel"></i>
                  </span>
                  <h5>Referees Training</h5>
                  <p>
                    Referees play a critical role ensuring
that games are conducted fairly and
according to the rules. However,
being a good referee requires more
than just a knowledge of the rules of
the game. It also requires excellent
communication skills, decisionmaking ability, and the ability to
maintain a calm and professional
d e m e a n o r i n h i g h - p r e s s u r e
situations.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="rad_box text-center">
                  <span class="icon_box">
                    <i class="fas fa-gavel"></i>
                  </span>
                  <h5>Sports Management training </h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="rad_box text-center">
                  <span class="icon_box">
                    <i class="fas fa-gavel"></i>
                  </span>
                  <h5>Residential Program </h5>
                  <p>
                    A residential training program is a
type of training program where
participants live and learn in the
same place for a set period of time.
T h e t r a i n i n g m a y i n v o l v e a
combination of lectures, workshops,
group activities, and practical
exercises. Meals and other amenities
are also typically provided as part of
the program.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Training;
