import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const Commercial = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          class="page__title align-items-center"
          style={{
            backgroundImage: `url("assets/img/bg/organisa-bg.jpg")`,
          }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
                COMMERCIAL
              </h3>
            </div>
          </div>
        </div>

        <div class="blog_area pt-80 pb-70">
          <div class="container custome-container">
            <div class="mblog_info mblog_details">
              <h6 class="mblog__title mb-15">Marketing</h6>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <div class="marketng_box text-center">
                    <img
                      src="/assets/img/event_sponsorship.jpg"
                      alt="EventSponsorship"
                      class="img-fluid"
                    />
                    <h4>Event Sponsorship</h4>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="marketng_box text-center">
                    <img
                      src="/assets/img/project_sponsorship.jpg"
                      alt="Project Sponsorship"
                      class="img-fluid"
                    />
                    <h4>Project Sponsorship </h4>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="marketng_box text-center">
                    <img
                      src="/assets/img/team_sales.jpg"
                      alt="Team	Sales"
                      class="img-fluid"
                    />
                    <h4>Team Sales</h4>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="marketng_box text-center">
                    <img
                      src="/assets/img/social_projects.jpg"
                      alt="social_projects"
                      class="img-fluid"
                    />
                    <h4>Social Projects</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg_img mt-30"
            data-background="/assets/img/vfok.jpg"
            style={{
              backgroundImage: 'url("/assets/img/vfok.jpg")',
            }}
          >
            <div class="container custome-container">
              <div
                class="section-wrap-two text-center wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                <h3 class="section-title-two  mb-20 white-color">TV & OTT</h3>
              </div>
              <div class="row mt-40">
                <div class="col-md-4">
                  <div class="or_box mb-30 t3">
                    <img src="/assets/img/broadcast.png" alt="Broadcast" />
                    <h4 class="mb-10 mt-20">Broadcast </h4>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="or_box mb-30 t3">
                    <img src="/assets/img/syndication.png" alt="syndication" />
                    <h4 class="mb-10 mt-20">Syndication </h4>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="or_box mb-30 t3">
                    <img src="/assets/img/ott_plaborm.png" alt="OTT Plaborm" />
                    <h4 class="mb-10 mt-20">OTT Platform </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container custome-container">
            <h6 class="mblog__title mb-15  mt-50">Content Syndication </h6>
            <div class="row ">
              <div class="col-md-6 mb-4">
                <div class="marketng_box text-center">
                  <img
                    src="/assets/img/aggregator_collaborations.jpg"
                    alt="Aggregator Collaborations"
                    class="img-fluid"
                  />
                  <h4>Aggregator Collaborations </h4>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="marketng_box text-center">
                  <img
                    src="/assets/img/global_broadcast_networks.jpg"
                    alt="Global	broadcast	Networks"
                    class="img-fluid"
                  />
                  <h4>Global broadcast Networks </h4>
                </div>
              </div>
            </div>

            <h6 class="mblog__title mb-15  mt-20">Public Viewing </h6>
            <div class="row ">
              <div class="col-md-6 mb-4">
                <div class="marketng_box text-center">
                  <img
                    src="/assets/img/os_spectators.jpg"
                    alt="On-site	Spectators"
                    class="img-fluid"
                  />
                  <h4>On-site Spectators</h4>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="marketng_box text-center">
                  <img
                    src="/assets/img/online_spectators.jpg"
                    alt="Online Spectators"
                    class="img-fluid"
                  />
                  <h4>Online Spectators </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Commercial;
