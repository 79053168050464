import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const OttSubscription = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
        <main>
        <div
          class="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/login.jpg")' }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
               OTT Subscription 
              </h3>
			 
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
             <h3 className="mblog__title mb-15">Subscribe to OTT Platform for 24x7 Content</h3> 
             <p>The	OTT	(Over-the-Top)	market	has	experienced	significant	growth	in	recent	years,	fueled	by	advancements	in	internet	connectivity,	the	proliferation	of	smartphones	and	smart	TVs,	and	changing	consumer	preferences	for	on-demand	content.	The	market	is	highly	competitive	and	dynamic,	with	numerous	players	vying	for	a	share	of	the	global	audience.</p> 
			 
			 <p>We	have	entered	into	partnership	with	UVTV	for	streaming	our	content.	The	UKL	segment	shall	stream	UKL	and	allied	Karate	Content	24x7	globally.	</p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default OttSubscription;
