import React, { useState, useEffect } from "react";
import Navbar1 from "../Common/Navbar1";
import Footer from "../Common/Footer";
import Sidebar from "../Common/Sidebar";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Jobs = () => {
  const [data, setData] = useState([]);
  const defaultImage = "assets/img/pay.jpg";

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getJobs();
  }, []);

  const getJobs = (e) => {
    axios.post(baseUrl + "/auth/get-job", {}).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setData(respp.data);
      }
    });
  };

  const applyForm = (id) => {
    var linkId = id;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var data = {
      id: linkId,
    };
    axios
      .post(baseUrl + "/auth/get-jobFormDetails", data, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          return false;
        } else {
          var goLink = "/applyJobs/" + btoa(linkId);
          window.location.href = goLink;
        }
      });
  };

  const showData = () => {
    var html = [];
    if (data.length > 0) {
      data.map(function (value, i) {
        html.push(
          <>
            <div class="col-lg-6 mb-3 mb-md-4">
              <div class="white_box play_earn h100">
                <div class="row">
                  <div class="form-group col-md-5">
                    <img
                      src={
                        value.job_image === null
                          ? defaultImage
                          : baseUrl + "/static/upload/" + value.job_image
                      }
                      alt="jobs"
                      class="img-fluid play_pay_img"
                    />
                  </div>
                  <div class="form-group col-md-7 p-md-0">
                    <ul class="list_dital">
                      <li>
                        <span class="lable_">Job Post</span>
                        {value.job_name}
                      </li>
                      <li>
                        <span class="lable_">Salary</span>
                        {value.salary}
                      </li>
                      <li>
                        <span class="lable_">Vacancy</span>
                        {value.vacancy}
                      </li>
                      <li>
                        <span class="lable_">Experience</span>
                        {value.experience}
                      </li>
                      <li>
                        <span class="lable_">Location</span>
                        {value.location}
                      </li>
                    </ul>
                  </div>
                  <div class="row align-items-end">
                    <div class=" col-md-9">
                      <b class="mt-0">Details</b>
                      <ul class="dit_ul">
                        <li>{value.details}</li>
                      </ul>
                    </div>

                    <div class="col-md-3 text-right">
                      <button
                        class="btn btn_man"
                        onClick={() => applyForm(value.id)}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    } else {
      html.push(<span>No Data Found</span>);
    }

    return html;
  };

  return (
    <>
      <Navbar1 />
      <Sidebar />

      <div class="dashbord_in">
        <div class="container custome-container">
          <h2 class="d_haddign">Jobs</h2>
          <div class="row">{showData()}</div>
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
    </>
  );
};

export default Jobs;
