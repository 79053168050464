import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const Organisa = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          class="page__title align-items-center"
          style={{
            backgroundImage: `url("assets/img/bg/organisa-bg.jpg")`,
          }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
                Organisa
              </h3>
            </div>
          </div>
        </div>

        <div class="blog_area pt-80 pb-70">
          <div class="container custome-container">
            <div class="mblog_info mblog_details">
              <h6 class="mblog__title mb-15">A BRIEF HISTORY OF KARATE</h6>
              <p>
                {" "}
                <i>
                  Over the years, the Karate community grew to nearly 190
                  million people and BoutsPro emerged as the umbrella
                  organization to support and nurture the arts, evolve mechanism
                  for their prosperity and popularity.
                </i>{" "}
              </p>
              <p>
                Evolution of Karate dates back to Before Christ era. It is
                believed that an Indian monk Bodhidharma (Daruma Daishi in
                Japanese and Ta-mo in Chinese) significantly contributed in its
                evolution from India to China and subsequently to Okinawa where
                the art evolved as Shuri-te, Naha-te and Tomari-te. Evolution
                process further continued in mainland Japan in 1930s where it
                was finally termed as Karate-do or the art of empty hand.{" "}
              </p>
              <p>
                In 1968, Sensei Hidetaka Nishiyama, Dr. Prof. Vladimir Jorga,
                Dr. Prof. Ilija Jorga, Mr. Jacques Delcourt, Mr. Ryoichi
                Sasakawa and a number of karate leaders gouped to form the World
                Body of Karate and regulate World Karate Championships.{" "}
              </p>
              <p>
                On 10 October 1970, the 1st World Karate Championship was
                organised in Tokyo, Japan. Over the years, there were paradigm
                shift in training methodology of a large group that promoted
                pure sports form of Karate and developed their technical rules
                identical to fencing sport making it a “touch and run-away
                sport”. This group is named and organised under the name of WKF
                while others started to evolve under new rules of professional
                karate. World Fudokan Federation (WFF) became instrumental in
                developing WPKC (World Professional Karate Corporation). WPKC is
                the sole World Governing Body for Professional Karate.
              </p>
              <p>
                The new digital and modern complex world demand very high
                quality products that would keep it thoroughly thrilled and
                entertained. New TEAM event of Karate where BUDO is protected,
                tradition is preserved, modernity is explored and the outcome is
                ultimate.{" "}
              </p>
              <p>
                Thus came the term of “Ultimate Karate” in existence with new,
                unique technical rule, developed and protected under the
                Intellectual Property Rights (IPR). The promoter of this format
                is Ultimate Karate Pvt Ltd hereinafter referred to as UKPL.
                Technically, Ultimate Karate is composed of Todome-Waza or
                Finishing Techniques whereby the athletes have ability to
                deliver multiple finishing blows that has capacity to neutralize
                and destroy opponents in a single blow.{" "}
              </p>
              <p>
                The Ultimate Karate Rules demand Extra-ordinary Power, Lightning
                Speed, Outstanding Control and could be compared with driving a
                high-speed car at maximum speed but returning home absolutely
                safe. Without these fundamental principles, “karate tournaments”
                could be best equated with a river that has no water, jungle
                that has no trees, human body that has no life.{" "}
              </p>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <img
                    src="/assets/img/organisa1.jpg"
                    alt="organisa1"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <img
                    src="/assets/img/organisa2.jpg"
                    alt="organisa2"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg_img mt-30"
            data-background="assets/img/vfok.jpg"
            style={{
                backgroundImage: 'url("/assets/img/vfok.jpg")',
            }}
          >
            <div class="container custome-container">
              <div
                class="section-wrap-two text-center wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                <h3 class="section-title-two  mb-20 white-color">
                  Various Formats of Karate
                </h3>
              </div>
              <div class="row mt-40">
                <div class="col-sm-6">
                  <div class="or_box mb-30 t3">
                    <h4 class="mb-10">Amateur Karate </h4>
                    <p class="mb-25">
                      Amateur Karate is defined as a sport where athletes have
                      to bear all expenses related with their training and
                      performance.
                      <br />
                      Amateur athletes are either supported by the self or in
                      the most rare cases by the cash-starved Sports departments
                      of various governments.{" "}
                    </p>
                  </div>
                </div>
                <div class=" col-sm-6">
                  <div class="or_box mb-30 t3">
                    <h4 class="mb-10">Pro-Am Karate </h4>
                    <p class="mb-25">
                      The Pro-Am Karate is defined as feeder events that allows
                      Amateur athletes to turn professionals.
                      <br />
                      Pro-Am athletes are partially supported by the
                      Federations/Councils but are deprived of regular salary or
                      remunerations.{" "}
                    </p>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="or_box mb-30 t3">
                    <h4 class="mb-10">Professional Karate </h4>
                    <p class="mb-25">
                      Professional Karate is defined as martial sport where
                      athletes receive huge remuneration for their performance
                      at tournaments.
                      <br />
                      Professional athleticism has come to the fore through a
                      combination of developments. Mass Media and increased
                      leisure have brought larger audiences, so that sports
                      organizations or teams can command large incomes. As a
                      result, more sportspeople can afford to make athleticism
                      their primary career, devoting the training time necessary
                      to increase skills, physical condition, and experience to
                      modern levels of achievement. This proficiency shall also
                      help boost the popularity of sports. WPKC is the sole
                      World Governing Body of Professional Karate. In the Year
                      2022, WPKC has been renamed as IPKC - International Pro
                      Karate Council
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container custome-container">
            <h2 class="mt-40">IPKC</h2>
            <h3>
              World Body: International Pro Karate Council
              <br />
              Indian Body: Indian Professional Karate Council
            </h3>

            <p>
              World of Combat Sports are governed along two lines, amateur
              sports & professional sports and could be best understood by
              boxing that has dis:nct structures of amateur and professional.
            </p>
            <p>
              <i>
                Karate has huge following all across India in form of amateur
                Karate. Amateur Karate is defined as a sport where athletes have
                to bear all expenses related with their performance. Such events
                are conducted under three (3) tournament formats of TradiAonal
                Karate, Sports Karate and Contact Karate.{" "}
              </i>
            </p>
            <p>
              Professional Karate is defined as mar:al sport where athletes
              receive remunera:on for their performance. It facilitates
              opportuni:es for karate prac::oners to develop their skill to make
              it their career and explore job opportuni:es in the field of
              karate.
            </p>
            <p>IPKC leads the movement of World Karate</p>
            <p>
              IPKC (Indian Professional Karate Council) is the na:onal body for
              professional Karate in India and sole member of the WPKC (World
              Professional Karate Council) in India. The WPKC has been renamed
              as the IPKC Interna:onal Pro Karate Council for effec:ve brand
              management of professional karate, that is popularly known as The
              Ul:mate Karate.
            </p>
            <p>
              Eversince 1983, the tradi:onal form of karate was organized and
              governed in India under the name of Tradi:onal Karate Federa:on of
              India (TKFI). In the year 2015, all tradi:onal forms of amateur
              karate resolved to converge into professional karate under the
              name of IPKC.{" "}
            </p>
            <p>
              Incorporated under Sec:on 8, IPKC (Indian Professional Karate
              Council) is a 100% Tax-exempted en3ty. Inheri:ng the legacy of 30
              annual Na:onal Championships of TKFI, the IPKC conducted 33rd
              Na:onals in Mumbai this year in Mumbai that selected athletes for
              upcoming League.
            </p>
            <p>
              Professional Karate has come to the fore through a combina:on of
              developments. Mass Media and increased leisure have brought larger
              audiences, so that karate teams can command large incomes. As a
              result, more sportspeople can afford to make Karate their primary
              career, devo:ng the training :me necessary to increase skills,
              physical condi:on and experience to modern levels of achievement.
              This proficiency shall also help boost the popularity of karate.{" "}
            </p>
            <p>
              Despite the fact that there is huge following of Karate in India,
              there was no professional system to take karate to the next level.
              Only IPKC can clear the blockade between “Opportuni:es” and
              “Beneficiaries” where coaches and players of karate will be
              benefiped with “bouquet of opportuni:es”.
            </p>

            <h2 class="mt-40">IPKC Governing Council</h2>
            <h3>
              World Body: International Pro Karate Council
              <br />
              Indian Body: Indian Professional Karate Council
            </h3>

            <div class="row mt-10">
              <div class="col-sm-8">
                <h3 class="mt-30">IPKC World Governing Council </h3>
                <div class="row ">
                  <div class="col-sm-6">
                    <div class="council_box ">
                      <h4 class="text-white">Supreme Council </h4>
                      <ul>
                        <li>Sensei Dr Prof Vladimir Jorga - Serbia</li>
                        <li> Sensei Dr Prof Ilija Jorga - Serbia </li>
                        <li>Sensei Rajeev Sinha - India </li>
                        <li>Dr Zurab Merabishilivi - Georgia </li>
                        <li>Sensei Mike Apan - USA </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="council_box ">
                      <h4 class="text-white">Execu3ve Board</h4>
                      <ul>
                        <li>Sensei Rachmy Soebajo - Czech Republic</li>
                        <li> Sensei Dr Karl Hans Koening - Germany </li>
                        <li>Sensei Edgar Vargas - Columbia</li>
                        <li>Sensei Alexandar Simic - Serbia </li>
                        <li>Sensei Petr Suja - Czech Republic </li>
                        <li>Sensei Simo Tilo - Switzerland </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <h3 class="mt-30">IPKC Indian Professional Karate Council </h3>
                <div class="council_box ">
                  <h4 class="text-white">Board Of Directors </h4>
                  <ul>
                    <li>Sensei Rajeev Sinha, Chairman</li>
                    <li>Sensei Sandeep S Salvi, Secretary General </li>
                  </ul>
                </div>
              </div>
            </div>

            <h2 class="mt-40">IPKC Contact</h2>
            <h3>
              World Body: International Pro Karate Council <br />
              Indian Body: Indian Professional Karate Council
            </h3>

            <div class="row council_box22">
              <div class="col-sm-4">
                <div class="council_box ">
                  <h4 class="text-white">
                    IPKC Interna3onal Pro Karate Council{" "}
                  </h4>
                  <a href="">info@ipkc.net</a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="council_box">
                  <h4 class="text-white">
                    IPKC Indian Professional Karate Council{" "}
                  </h4>
                  <a href="">india@ipkc.net</a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="council_box ">
                  <h4 class="text-white">IPKC Donor Cards</h4>
                  <a href="">donors@ipkc.net</a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="council_box ">
                  <h4 class="text-white">IPKC Technical </h4>
                  <a href="">technical@ipkc.net </a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="council_box ">
                  <h4 class="text-white">IPKC Sponsorship</h4>
                  <a href="">sponsorship@ipkc.net </a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="council_box ">
                  <h4 class="text-white">Helpline</h4>
                  <a href="">help@ipkc.net</a>
                </div>
              </div>
            </div>

            <h2 class="mt-40">IPKC Jobs</h2>
            <h3>
              World Body: International Pro Karate Council <br />
              Indian Body: Indian Professional Karate Council
            </h3>

            <table class="table table-striped mt-20">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Jobs</th>
                  <th>Loca3on</th>
                  <th>Number of Opening</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>September 2022</td>
                  <td>Marke:ng Execu:ves</td>
                  <td>Across India Across Europe</td>
                  <td>100 (India) 20 (Europe) </td>
                </tr>
                <tr>
                  <td>October 2022</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>November 2022</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>December 2022</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Organisa;
