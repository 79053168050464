import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [device, setDevice] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [btn, setBtn] = useState(false);

  useEffect(() => {
    DeviceName();
    getData();
    getGeoInfo();
    noLogin();
  }, []);

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryName(data.country_name);
        setCityName(data.city);
      })
      .catch((error) => {});
  };

  const DeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
      return false;
    }
    if (isAndroid) {
      setDevice("Android");
      return false;
    }
    if (isIOS) {
      setDevice("IOS");
      return false;
    }
    if (isDesktop) {
      setDevice("Desktop");
      return false;
    }
  };

  const onChangeEmail = (event) => {
    var email = event;
    if (!email) {
      setErrorMsgEmail("Please enter email");
      setEmail(email);
      return false;
    }
    if (email.includes(" ")) {
      setErrorMsgEmail("No blank space allowed");
      setEmail(email);
      return false;
    }
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
    if (!email.match(emailReg)) {
      setErrorMsgEmail("Please enter a valid email");
      setEmail(email);
      return false;
    } else {
      setErrorMsgEmail("");
    }
    setEmail(email);
    setErrorMsgEmail("");
  };

  const onChangePass = (event) => {
    var Password = event;

    if (!Password) {
      setPassword(Password);
      setErrorMsgPassword("Please enter password");
      return false;
    }

    setPassword(Password);
    setErrorMsgPassword("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (!email && !password) {
      setErrorMsgEmail("Please enter email");
      setErrorMsgPassword("Please enter password");
      return false;
    }

    if (!email) {
      setErrorMsgEmail("Please enter email");
      return false;
    }
    if (email.includes(" ")) {
      setErrorMsgEmail("No blank space allowed");
      return false;
    }
    if (!email.match(emailReg)) {
      setErrorMsgEmail("Please enter a valid email");
      return false;
    }

    if (password === "" || password === undefined) {
      setErrorMsgPassword("Please enter password");
      return false;
    }
    const loginData = {
      email: email,
      Password: password,
    };
    axios.post(baseUrl + "/auth/login", loginData).then((resp) => {
      var respp = resp.data;
      setBtn(true);

      if (respp.success === true) {
        toast.dismiss();
        localStorage.setItem("id", respp.data[0].id);
        localStorage.setItem("email", email);
        localStorage.setItem("token", respp.token);
        // toast.success(respp.message);

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const ipData = {
          ip: localStorage.getItem("ip"),
          device: device,
          browser: browserName + " " + browserVersion,
          osName: osName + " " + osVersion,
          cityName: cityName,
          countryName: countryName,
          msg: "Logged In",
        };

        axios
          .post(baseUrl + "/auth/loginHistory", ipData, config)
          .then((res) => {
            const login_data = res.data;
            if (login_data.success === true) {
              window.location.href = "/dashboard";
            }
          });

        return false;
      } else {
        toast.dismiss();
        toast.error(respp.message);
        setBtn(false);
      }
    });
  };

  // password eye

  const handleClick = () => {
    if (type === "password") {
      setType("text");
      return false;
    } else {
      setType("password");
    }
  };

  const noLogin = () => {
    if (localStorage.getItem("token")) {
      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/register" ||
        window.location.pathname === "/forgot"
      ) {
        return (window.location.href = "/dashboard");
      }
    }
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: `url("assets/img/bg/login.jpg")`,
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                <br />
              </h3>
            </div>
          </div>
        </div>

        <div className=" pb-70">
          <div className="container custome-container">
            <div className="row align-items-center">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="login_box  mb-35">
                  <div className="section-wrap mb-35">
                    <h2 className=" mb-20 text-center">
                      Login to you account
                      <br />
                      with email and password.
                    </h2>
                  </div>
                  <form action="#">
                    <div className="tp-support-form-field mb-20">
                      <input
                        type="email"
                        placeholder="Email ID"
                        onChange={(e) => onChangeEmail(e.target.value)}
                      />
                      <div style={{ color: "red" }}>{errorMsgEmail}</div>
                    </div>

                    <div className="tp-support-form-field password_box mb-10">
                      <input
                        type={type}
                        placeholder="Password"
                        onChange={(e) => onChangePass(e.target.value)}
                      />
                      <span
                        className="password__show eye1"
                        onClick={() => handleClick()}
                      >
                        {type === "text" ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <div style={{ color: "red" }}>{errorMsgPassword}</div>
                    </div>
                    <div className="support-btn text-right h6 mb-20">
                      <Link to="/forgot">Forgot Password?</Link>
                    </div>

                    <div className="support-btn text-center">
                      <button
                        type="submit"
                        className="tp-btn-round"
                        onClick={(e) => handleSubmit(e)}
                        disabled={btn}
                      >
                        Log In<i className="fal fa-chevron-double-right"></i>{" "}
                      </button>
                    </div>
                  </form>

                  <div className="support-btn text-center h5 mt-3">
                    Don't have a account?{" "}
                    <Link to="/register" className="">
                      Sign Up{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </main>
      <Footer />
      {/* {noLogin()} */}
    </>
  );
};

export default Login;
