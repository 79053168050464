import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const Videos = () => {
  return (
    <>
      <Navbar />
      <Sidebar />

      <main>
        <div
          class="page__title align-items-center"
          style={{
            backgroundImage: 'url("assets/img/bg/login.jpg")',
          }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">Videos</h3>
            </div>
          </div>
        </div>

        <div class="blog_area pt-80 pb-70">
          <div class="container custome-container">
            <h6 class="mblog__title mb-15">MATCH VIDEOS</h6>
            <div class="row">
              <div class="col-md-4 mb-4">
                <div class="marketng_box text-center">
                  <iframe
                    style={{
                      width: "100%",
                    }}
                    height="200"
                    src="https://www.youtube.com/embed/Bxn0UffJWO8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <h4>Rules</h4>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div class="marketng_box text-center">
                  <iframe
                    style={{
                      width: "100%",
                    }}
                    height="200"
                    src="https://www.youtube.com/embed/b9MoXre_mww"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <h4>Current</h4>
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div class="marketng_box text-center">
                  <iframe
                    style={{
                      width: "100%",
                    }}
                    height="200"
                    src="https://www.youtube.com/embed/xRjv9TNmnPk"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <h4>Archive</h4>
                </div>
              </div>
            </div>

            <div class="mt-50">
              <h6 class="mblog__title mb-15">TRAINING VIDEOS</h6>
              <div class="row">
                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/X5D2revZGjs"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Athletes Training</h4>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/iURcl94MBv4"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Coaches Training </h4>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/xRjv9TNmnPk"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Referees Training </h4>
                  </div>
                </div>

                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/TWGyKLn6E4M"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Sports Management Training</h4>
                  </div>
                </div>

                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/TIIrfXoq90c"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Residen:al Program</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-50">
              <h6 class="mblog__title mb-15">NON-TOURNAMENT VIDEOS </h6>
              <div class="row">
                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/AAlzaCkKyiM"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Interviews</h4>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/AxHqkSR0x6c"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Documentaries</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Videos;
