import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const privilegeCard = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
          <main>
        <div
          className="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/login.jpg")' }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
              Privilege Card 
              </h3>
			 
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
             <h3 className="mblog__title mb-15">Fractional Ownership</h3> 
            <p>	Ownership	 of	 sports	 teams	 is	 typically	 reserved	 for	 wealthy	 individuals,	 corporations,	 or	 consortiums	 that	 invest	 significant	 amounts	 of	 money	 to	 acquire	 a	controlling	stake	in	a	team</p>
            <p>	Fractional	ownership	typically	refers	to	a	shared	ownership	model	where	multiple	individuals	or	en77es	collectively	own	an	asset.	Each	owner	holds	a	fraction	of	
the	asset's	value,	usually	represented	as	shares	or	units.	This	concept	has	been	applied	to	various	assets	like	real	estate,	luxury	items,	and	even	sports	teams.	</p>
            <p>	The	 idea	 behind	 fractional	 ownership	 of	 sports	 teams	 is	 to	 provide	 individuals	 with	 the	 opportunity	 to	 own	 a	 portion	 of	 a	 team,	 which	 traditionally	 requires	significant	 capital	 investment.	 By	 dividing	 the	 ownership	 into	 fractional	 shares,	 more	 people	 can	 participate	 and	 enjoy	 the	 benefits	 associated	 with	 team	ownership.	</p>
               <p>Some	potential	benefits	of	fractional	ownership	of	UKC	teams	include:	</p>
  
			
			<ul className="ul_content">
			   <li>
			     <b>Economic	 participation:</b>	 Fractional	 owners	 can	 potentially	 benefit	 from	 the	 financial	 success	 of	 the	 team,	 such	 as	 revenue	 generated	 from	 ticket	 sales,	merchandise,	sponsorships,	and	broadcasting	rights.	
			   </li> 
			    <li>
			     <b>Insider	access:	</b>Owners	may	gain	exclusive	access	to	team	events,	VIP	experiences,	and	behind-the-scenes	access	that	the	general	public	typically	doesn't	have.	
			   </li>
			    <li>
			     <b>Networking	opportunities:</b>	Fractional	ownership	can	provide	opportunities	to	connect	with	like-minded	individuals,	including	other	owners,	influential	figures,	and	professionals	within	the	sports	industry.
			   </li>
			    <li>
			     <b>Emotional	engagement:</b>	Owning	a	fraction	of	a	team	can	enhance	the	emotional	connection	and	sense	of	pride	in	supporting	the	team's	success.
			   </li>
			    <li>  <b>Potential	 for	 capital	 appreciation:</b>	 If	 the	 value	 of	 the	 team	 increases	 over	 time,	 fractional	 owners	 may	 benefit	 from	 the	 appreciation	 of	 their	 ownership	interest.
			   
			   </li>
			   
			   
			 
			</ul>
			<p><b>Privilege	Cards	shall	be	available	for	specific	countries	that	do	not	have	any	Master	Franchise.</b></p>
			
			
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default privilegeCard;
