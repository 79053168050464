import React, { useState, useEffect } from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [code, setCode] = useState("");

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "email") {
      if (!value) {
        setErrorEmail("Please enter email");
        setEmail(value);
        return false;
      }
      if (value.includes(" ")) {
        setErrorEmail("No blank space allowed");
        setEmail(value);
        return false;
      }
      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
      if (!value.match(emailReg)) {
        setErrorEmail("Please enter a valid email");
        setEmail(value);
        return false;
      } else {
        setErrorEmail("");
      }
      setEmail(value);
      setErrorEmail("");
    }
  };

  const submit = (e) => {
    e.preventDefault();
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
    if (!email) {
      setErrorEmail("Please enter email");
      return false;
    }
    if (email.includes(" ")) {
      setErrorEmail("No blank space allowed");
      return false;
    }
    if (!email.match(emailReg)) {
      setErrorEmail("Please enter a valid email");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const data = {
      email: email,
    };
    axios.post(baseUrl + "/auth/forgot", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        setCode(respp.code.newCode);
        toast.success(respp.message);

        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };
  const noLogin = () => {
    if (localStorage.getItem("token")) {
      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/register" ||
        window.location.pathname === "/forgot"
      ) {
        return (window.location.href = "/dashboard");
      }
    }
  };

  return (
    <>
      <Navbar />
      <Sidebar />

      <div
        className="page__title align-items-center"
        style={{
          backgroundImage: `url("assets/img/bg/login.jpg")`,
        }}
      >
        <div className="container">
          <div className="page__title-content text-center">
            <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
              <br />
            </h3>
          </div>
        </div>
      </div>

      <div className=" pb-70">
        <div className="container custome-container">
          <div className="row align-items-center">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="login_box  mb-35">
                <div className="section-wrap mb-35">
                  <span>
                    <h2 className=" mb-20 text-center">
                      Forgot Password <h4 className="text-right">{code}</h4>
                    </h2>
                  </span>
                </div>
                <form action="#">
                  <div className="tp-support-form-field mb-20">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email ID"
                      value={email}
                      onChange={(e) => handlechange(e)}
                    />
                    <div style={{ color: "red" }}>{errorEmail}</div>
                  </div>
                  <div className="support-btn text-center">
                    <button
                      type="submit"
                      className="tp-btn-round"
                      onClick={(e) => submit(e)}
                    >
                      Request a new Password
                      <i className="fal fa-chevron-double-right"></i>{" "}
                    </button>
                  </div>
                </form>

                <div className="support-btn text-center h5 mt-3">
                  Don't have a account?{" "}
                  <Link to="/register" className="">
                    Sign Up{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {noLogin()}

      <Footer />
    </>
  );
};

export default Forgot;
