import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";
const Medical = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          class="page__title align-items-center"
          style={{
            backgroundImage: `url("assets/img/medicine-bg.jpg")`,
          }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
                IPKC Sports Medicine
              </h3>
            </div>
          </div>
        </div>

        <div class="blog_area pt-80 pb-70">
          <div class="container custome-container">
            <div class="mblog_info mblog_details">
              <h6 class="mblog__title mb-15">MEDICAL</h6>
              <div class="medical_box mb-40 mt-20">
                <div class="row ">
                  <div class="col-md-4 ">
                    <img
                      src="/assets/img/general_education.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-md-8 ">
                    <h3>GENERAL EDUCATION </h3>
                    <p>
                      IPKC to foray into the field of education by establishing
                      education institutes, residential schools at select
                      loca:ons.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, opAon voluptatum usu ex, sea
                      purto cetero raAonibus an, nihil Abique pri et. Ius no
                      viris laudem, sea ei alterum ocurreret. Elitr audiam sit
                      ne. Ex sed qualisque assenAor, at diceret inermis
                      dissenAet vis. <br />
                      Ius ad deleniA eloquenAam, commodo vivendo usu no, iusto
                      alienum insolens duo ad. Nostro assenAor sadipscing nam
                      cu, ferri vocibus sea ne. Pri nostrud offendit at, cu pri
                      inani Ancidunt repudiandae. Et libris detracto legendos
                      usu, nam cu porro menandri scribentur. Mel an laoreet
                      consequuntur, eam eros noluisse similique ut.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="medical_box mb-40 mt-40">
                <div class="row ">
                  <div class="col-md-4 ">
                    <img
                      src="/assets/img/injury_prevention.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-md-8 ">
                    <h3>INJURY PREVENTION</h3>
                    <p>
                      IPKC to foray into the field of education by establishing
                      education institutes, residential schools at select
                      loca:ons.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, opAon voluptatum usu ex, sea
                      purto cetero raAonibus an, nihil Abique pri et. Ius no
                      viris laudem, sea ei alterum ocurreret. Elitr audiam sit
                      ne. Ex sed qualisque assenAor, at diceret inermis
                      dissenAet vis. <br />
                      Ius ad deleniA eloquenAam, commodo vivendo usu no, iusto
                      alienum insolens duo ad. Nostro assenAor sadipscing nam
                      cu, ferri vocibus sea ne. Pri nostrud offendit at, cu pri
                      inani Ancidunt repudiandae. Et libris detracto legendos
                      usu, nam cu porro menandri scribentur. Mel an laoreet
                      consequuntur, eam eros noluisse similique ut.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="medical_box mb-40 mt-40">
                <div class="row ">
                  <div class="col-md-4 ">
                    <img
                      src="/assets/img/sports_medicine.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-md-8 ">
                    <h3>SPORTS MEDICINE</h3>
                    <p>
                      IPKC to foray into the field of education by establishing
                      education institutes, residential schools at select
                      loca:ons.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, opAon voluptatum usu ex, sea
                      purto cetero raAonibus an, nihil Abique pri et. Ius no
                      viris laudem, sea ei alterum ocurreret. Elitr audiam sit
                      ne. Ex sed qualisque assenAor, at diceret inermis
                      dissenAet vis. <br />
                      Ius ad deleniA eloquenAam, commodo vivendo usu no, iusto
                      alienum insolens duo ad. Nostro assenAor sadipscing nam
                      cu, ferri vocibus sea ne. Pri nostrud offendit at, cu pri
                      inani Ancidunt repudiandae. Et libris detracto legendos
                      usu, nam cu porro menandri scribentur. Mel an laoreet
                      consequuntur, eam eros noluisse similique ut.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="medical_box mb-40 mt-40">
                <div class="row ">
                  <div class="col-md-4 ">
                    <img
                      src="/assets/img/alternative_traditional.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-md-8 ">
                    <h3>ALTERNATIVE TREATMENT </h3>
                    <p>
                      IPKC to foray into the field of education by establishing
                      education institutes, residential schools at select
                      loca:ons.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, opAon voluptatum usu ex, sea
                      purto cetero raAonibus an, nihil Abique pri et. Ius no
                      viris laudem, sea ei alterum ocurreret. Elitr audiam sit
                      ne. Ex sed qualisque assenAor, at diceret inermis
                      dissenAet vis. <br />
                      Ius ad deleniA eloquenAam, commodo vivendo usu no, iusto
                      alienum insolens duo ad. Nostro assenAor sadipscing nam
                      cu, ferri vocibus sea ne. Pri nostrud offendit at, cu pri
                      inani Ancidunt repudiandae. Et libris detracto legendos
                      usu, nam cu porro menandri scribentur. Mel an laoreet
                      consequuntur, eam eros noluisse similique ut.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="medical_box mb-40 mt-40">
                <div class="row ">
                  <div class="col-md-4 ">
                    <img
                      src="a/ssets/img/oriental_approach.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-md-8 ">
                    <h3>ORIENTAL APPROACH </h3>
                    <p>
                      IPKC to foray into the field of education by establishing
                      education institutes, residential schools at select
                      loca:ons.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, opAon voluptatum usu ex, sea
                      purto cetero raAonibus an, nihil Abique pri et. Ius no
                      viris laudem, sea ei alterum ocurreret. Elitr audiam sit
                      ne. Ex sed qualisque assenAor, at diceret inermis
                      dissenAet vis. <br />
                      Ius ad deleniA eloquenAam, commodo vivendo usu no, iusto
                      alienum insolens duo ad. Nostro assenAor sadipscing nam
                      cu, ferri vocibus sea ne. Pri nostrud offendit at, cu pri
                      inani Ancidunt repudiandae. Et libris detracto legendos
                      usu, nam cu porro menandri scribentur. Mel an laoreet
                      consequuntur, eam eros noluisse similique ut.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Medical;
