import React, { useState } from "react";
import Navbar from "../Common/Navbar";
import Footer from "../Common/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../Common/Baseurl";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [refer, setRefer] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [btn, setBtn] = useState(false);

  const onChangeEmail = (event) => {
    var email = event;
    if (!email) {
      setErrorMsgEmail("Please enter email");
      setEmail(email);
      return false;
    }
    if (email.includes(" ")) {
      setErrorMsgEmail("No blank space allowed");
      setEmail(email);
      return false;
    }
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
    if (!email.match(emailReg)) {
      setErrorMsgEmail("Please enter a valid email");
      setEmail(email);
      return false;
    } else {
      setErrorMsgEmail("");
    }
    setEmail(email);
    setErrorMsgEmail("");
  };

  const onChangePass = (event) => {
    var Password = event;
    // this.setState({ PasswordIssueOnBlur: "" });
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!Password) {
      setPassword(Password);
      setErrorMsgPassword("Please enter password");
      return false;
    }
    if (Password.length < 8) {
      setPassword(Password);
      setErrorMsgPassword(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }
    if (!Password.match(regex)) {
      setPassword(Password);
      setErrorMsgPassword(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }
    setPassword(Password);
    setErrorMsgPassword("");
  };

  const onChangeRefer = (e) => {
    let { name, value } = e.target;

    if (name === "refer") {
      setRefer(value);
    }
    setRefer(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
    var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";

    if (!email && !password) {
      setErrorMsgEmail("Please enter email");
      setErrorMsgPassword("Please enter password");
      return false;
    }

    if (!email) {
      setErrorMsgEmail("Please enter email");
      return false;
    }
    if (email.includes(" ")) {
      setErrorMsgEmail("No blank space allowed");
      return false;
    }
    if (!email.match(emailReg)) {
      setErrorMsgEmail("Please enter a valid email");
      return false;
    }

    if (password === "" || password === undefined) {
      setErrorMsgPassword("Please enter password");
      return false;
    }
    if (password.length < 8) {
      setErrorMsgPassword(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    if (!password.match(regex)) {
      setErrorMsgPassword(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    }

    const signupData = {
      email: email,
      password: password,
      referId: refer,
    };
    axios.post(baseUrl + "/auth/register", signupData).then((resp) => {
      var respp = resp.data;
      setBtn(true);
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        setTimeout(() => {
          setBtn(false);
          window.location = "/login";
        }, 4000);
        return false;
      } else {
        toast.dismiss();
        toast.error(respp.message);
        setBtn(false);
      }
    });
  };

  // password eye

  const handleClick = () => {
    if (type === "password") {
      setType("text");
      return false;
    } else {
      setType("password");
    }
  };
  const noLogin = () => {
    if (localStorage.getItem("token")) {
      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/register" ||
        window.location.pathname === "/forgot"
      ) {
        return (window.location.href = "/dashboard");
      }
    }
  };

  return (
    <>
      <Navbar />

      <div
        class="page__title align-items-center"
        style={{
          backgroundImage: `url("assets/img/bg/login.jpg")`,
        }}
      >
        <div class="container">
          <div class="page__title-content text-center">
            <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
              <br />
            </h3>
          </div>
        </div>
      </div>
      <div class=" pb-70">
        <div class="container custome-container">
          <div class="row align-items-center">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
              <div class="login_box  mb-35">
                <div class="section-wrap mb-35">
                  <h2 class=" mb-20 text-center">
                    Create a new account
                    <br />
                    with email and password.
                  </h2>
                </div>
                <form action="#">
                  <div class="tp-support-form-field mb-20">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email ID"
                      value={email}
                      onChange={(event) => onChangeEmail(event.target.value)}
                    />
                  </div>
                  <div style={{ color: "red" }}>{errorMsgEmail}</div>
                  <div class="tp-support-form-field password_box mb-20">
                    <input
                      type={type}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => onChangePass(e.target.value)}
                    />
                    <span
                      className="password__show eye1"
                      onClick={() => handleClick()}
                    >
                      {type === "text" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                  <div style={{ color: "red" }}>{errorMsgPassword}</div>
                  <div class="tp-support-form-field mb-20">
                    <input
                      type="refer"
                      name="refer"
                      placeholder="Referral ID"
                      value={refer}
                      onChange={onChangeRefer}
                    />
                  </div>
                  <div class="support-btn text-center">
                    <button
                      type="submit"
                      class="tp-btn-round"
                      onClick={(e) => handleSubmit(e)}
                      disabled={btn}
                    >
                      Sign Up<i class="fal fa-chevron-double-right"></i>{" "}
                    </button>
                  </div>
                </form>

                <div class="support-btn text-center h5 mt-3">
                  Already Have a account?{" "}
                  <a href={"/login"} class="">
                    Login{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {noLogin()}
      <Footer />
    </>
  );
};

export default Register;
