import React from "react";
import Footer from "../../Common/Footer";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";

const MatchRules = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: 'url("assets/img/bg/login.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                Match Rules
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
		  <h3>1. Team Match Rules</h3>
          <ul className="ul_list">
		   <li>UKL	is	a	Team	match	with	6	male	athletes	in	each	team.	</li>
		   <li>	
 Three	(3)	athletes	play	simultaneously	as	a	team.	Any	athlete	could	be	replaced	by	reserved	athlete	at	any	point	during	the	breaks.	</li>
		   <li>
There	is	One	(1)	female	athlete	in	each	team	for	the	Toss-match	that	decides	the	selecFon	of	side	of	Challengers	and	Defenders.		
</li>
		   <li>Each	team	has	one	Manager/coach.</li>
		  </ul><br/>
		  <h3>2. Team Match Rules</h3>
          <ul className="ul_list">
		   <li>Total	duraFon	of	a	Match	including	slow-moFon	replay	breaks	is	40	minutes.
</li>
		  </ul>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default MatchRules;
