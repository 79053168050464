// import React, { useState, useEffect } from "react";
import React, { useState, useEffect } from "react";

import Footer1 from "../Common/Footer1";
import Navbar1 from "../Common/Navbar1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const ELearning = () => {
  const [learnData, setLearnData] = useState([]);
  const [learnStatus, setLearnStatus] = useState("");

  const defaultImage = "assets/img/pay.jpg";

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getCourses", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setLearnData(respp.data);
        setLearnStatus(respp.success);
        return false;
      }
      setLearnStatus(respp.success);
    });
  };

  const applyForm = (id) => {
    var linkId = id;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var data = {
      id: linkId,
    };
    axios
      .post(baseUrl + "/auth/get-learnFormDetails", data, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          return false;
        } else {
          var goLink = "/learnEarn/" + btoa(linkId);
          window.location.href = goLink;
        }
      });
  };

  const showData = () => {
    return learnData.map((value, i) => {
      if (learnStatus === true)
        return (
          <>
            <div class="col-lg-6 mb-3 mb-md-4">
              <div class="white_box play_earn h100">
                <div class="row">
                  <div class="form-group col-md-5">
                    <img
                      src={
                        value.image === null
                          ? defaultImage
                          : baseUrl + "/static/upload/" + value.image
                      }
                      alt="jobs"
                      class="img-fluid play_pay_img"
                    />
                  </div>
                  <div class="form-group col-md-7 p-md-0">
                    <ul class="list_dital">
                      <li>
                        <span class="lable_">Course Name</span>
                        {value.course_name}
                      </li>
                      <li>
                        <span class="lable_">Course Duration </span>
                        {value.duration}
                      </li>
                      <li>
                        <span class="lable_">Course Date</span>
                        {moment(value.starting_date).format("ll")}
                      </li>
                      <li>
                        <span class="lable_">Course fees</span>
                        {value.fees}
                      </li>
                    </ul>
                  </div>
                  <div class="row align-items-end">
                    <div class=" col-md-9">
                      <b class="mt-0">Description</b>
                      <ul class="dit_ul">
                        <li>{value.description}</li>
                      </ul>
                    </div>

                    <div class="col-md-3 text-right">
                      <button
                        class="btn btn_man"
                        onClick={() => applyForm(value.id)}
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    });
  };

  return (
    <>
      <Navbar1 />
      {/* <Sidebar1 /> */}
      <main>
        <div class="dashbord_in">
          <div class="container custome-container">
            <h2 class="d_haddign">Subscribe To E-Learning Program</h2>
            <div class="row">{showData()}</div>
          </div>
        </div>
      </main>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer1 />
    </>
  );
};

export default ELearning;
