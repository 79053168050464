import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const WorldLeague = () => {
  return (
    <>
      <Navbar />
      <Sidebar />

      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: 'url("/assets/img/bg/wl-bg.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                World League
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <h3 className="mblog__title mb-15">COUNTRIES</h3>
            <h4 className="mb-15">Phase-1 Teams</h4>
            <div className="row">
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/india.png" alt="India" />
                  <h5>INDIA</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/uae.png" alt="UAE" />
                  <h5>UAE</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/serbia.png" alt="Serbia" />
                  <h5>SERBIA</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/italy.png" alt="italy" />
                  <h5>ITALY</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/germany.png" alt="germany" />
                  <h5>GERMANY</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/france.png" alt="france" />
                  <h5>FRANCE</h5>
                </div>
              </div>
            </div>

            <h4 className="mb-15 mt-20">Phase-2 New Teams</h4>
            <div className="row">
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/indonesia.png" alt="Indonesia" />
                  <h5>Indonesia</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/canada.png" alt="Canada" />
                  <h5>Canada </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/usa.png" alt="USA" />
                  <h5>USA</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img
                    src="/assets/img/flag/uk.png"
                    alt="uk"
                  />
                  <h5>UK</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/czech_republic.png" alt="czech_republic" />
                  <h5>Czech Republic</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/russia.png" alt="Russia" />
                  <h5>Russia</h5>
                </div>
              </div>
            </div>
           
		   <h4 className="mb-15 mt-20">Phase-3 New Teams</h4>
            <div className="row">
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/japan.png" alt="Japan" />
                  <h5>Japan</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/romania.png" alt="Romania" />
                  <h5>Romania </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/brazil.png" alt="Brazil" />
                  <h5>USA</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img
                    src="/assets/img/flag/poland.png"
                    alt="Poland"
                  />
                  <h5>Poland</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/egypt.png" alt="Egypt" />
                  <h5>Egypt</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/south_africa.png" alt="South Africa" />
                  <h5>South Africa</h5>
                </div>
              </div>
            </div>
			
			<h4 className="mb-15 mt-20">Phase-4 New Teams</h4>
            <div className="row">
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/australia.png" alt="Australia" />
                  <h5>Australia</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/new_zealand.png" alt="New Zealand" />
                  <h5>New Zealand </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/switzerland.png" alt="Switzerland" />
                  <h5>Switzerland</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img
                    src="/assets/img/flag/portugal.png"
                    alt="Portugal"
                  />
                  <h5>Portugal</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/malaysia.png" alt="Malaysia" />
                  <h5>Malaysia</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/bahrain.png" alt="Bahrain" />
                  <h5>Bahrain</h5>
                </div>
              </div>
            </div>
	<h4 className="mb-15 mt-20">Phase-5 New Teams</h4>
            <div className="row">
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/saudiarabia.png" alt="Saudi Arabia" />
                  <h5>Saudi Arabia</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/argentina.png" alt="Argentina" />
                  <h5>Argentina</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/spain.png" alt="Spain" />
                  <h5>Spain</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img
                    src="/assets/img/flag/singapore.png"
                    alt="Singapore"
                  />
                  <h5>Singapore</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/qatar.png" alt="Qatar" />
                  <h5>Qatar</h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/georgia.png" alt="Georgia" />
                  <h5>Georgia</h5>
                </div>
              </div>
            </div>

            <h3 className="mblog__title mb-20 mt-50">SCHEDULE</h3>
            <div className="row">
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-users-medical"></i>
                  </span>
                  <h5>TEAM SELECTION </h5>
                  <ul>
                    <li>Selection Process</li>
                    <li>Timeline</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-globe"></i>
                  </span>
                  <h5>WORLD MATCHES </h5>
                  <ul>
                    <li>Schedule</li>
                    <li>Date</li>
                    <li>Place</li>
                    <li>Teams</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-clock"></i>
                  </span>
                  <h5>TELECAST TIMING</h5>
                  <ul>
                    <li>Telecast Timing</li>
                    <li>Channel details</li>
                    <li>Language</li>
                  </ul>
                </div>
              </div>
            </div>
            <h3 className="mblog__title mb-20 mt-50">ATHLETES</h3>
            <div className="row">
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-running"></i>
                  </span>
                  <h5>ATHLETES PROFILE</h5>
                  <ul>
                    <li>Selection Process</li>
                    <li>Timeline</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-gavel"></i>
                  </span>
                  <h5>ATHLETES AUCTION </h5>
                  <ul>
                    <li>Fixed Floor Price</li>
                    <li>Auction Model </li>
                    <li>Place of Auction </li>
                    <li>Teams Assigned </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-exchange"></i>
                  </span>
                  <h5>ATHLETES TRANSFER </h5>
                  <ul>
                    <li>Transfer Process</li>
                    <li>Talent Acquisition</li>
                    <li>Dispute Resolution</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default WorldLeague;
