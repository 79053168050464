import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <footer>
        <div
          className="footer-area"
          style={{
            backgroundImage: `url("/assets/img/bg/footer-bg.jpg")`,
          }}
        >
          <div className="footer-content pt-80 pb-45">
            <div className="container custome-container">
              <div className="row g-0">
                <div className="col-lg-4 col-md-8 col-sm-8">
                  <div
                    className="footer-widget fotter-col2 wow fadeInUp mb-40"
                    data-wow-delay=".3s"
                  >
                    <div className="footer-logo mb-25">
                      <Link to="/">
                        <img src="/assets/img/logo/logo.png" alt="" />
                      </Link>
                    </div>
                   <p className="pb-30">
                    ULTIMATE KARATE COUNCIL	d.o.o.	
Is	the	holder	of	UKL	and	allied	
IPR
                    </p>
                    <div className="footer-social">
                      <a target="_blank" href="https://www.facebook.com/UKL.WORLD">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a target="_blank" href="https://twitter.com/uklworld">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://www.instagram.com/ukl.world/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4">
                  <div
                    className="footer-widget wow fadeInUp mb-40"
                    data-wow-delay=".6s"
                  >
                    <h5 className="fotter_widget-title mb-35">Quick Link</h5>
                    <ul className="fotter_project_lists">
                      <li>
                        <a href="#">About </a>
                      </li>
                      <li>
                        <a href="#">Services </a>
                      </li>
                      <li>
                        <a href="#">Council </a>
                      </li>
                      <li>
                        <a href="#">Players & Masters </a>
                      </li>
                      <li>
                        <a href="#">Contact </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="footer-widget wow fadeInUp mb-40"
                    data-wow-delay=".9s"
                  >
                    <h5 className="fotter_widget-title mb-35">Contact Info</h5>
                    <div className="footer__info">
                      <ul>
                        <li className="d-flex align-items-start pt-5 mb-20">
                          <div className="footer__info-icon mr-20">
                            <i className="fas fa-map-marker-alt"></i>
                          </div>
                          <div className="footer__info-text">
                            <span>
                             UKC,	Bulevar	Mihajla	Pupina		
11000	Belgrade,	Serbia
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-start mb-20">
                          <div className="footer__info-icon mr-20">
                            <i className="far fa-envelope-open"></i>
                          </div>
                          <div className="footer__info-text">
                            <span>Email:</span>
                            <a href="mailto:info@ukl.world">info@ukl.world
                            </a>
                          </div>
                        </li>
                        <li className="d-flex align-items-start mb-20">
                          <div className="footer__info-icon mr-20">
                            <i className="fas fa-phone-alt"></i>
                          </div>
                          <div className="footer__info-text">
                            <span>Phone:</span>
                            <a href="tel:381654443950">(+381)	65	444	3950</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right-area">
            <div className="container custome-container">
              <div className="copyright-info">
                <div className="owner_name">
                  <p>
                    Copyright <a href="#">UKL</a> ©2022. All Rights Reserved
                  </p>
                </div>
                <div className="copy-right_useful_link">
                  <a href="#">Terms & Conditions</a>
                  <a href="#">. Services</a>
                  <a href="#">. Privacy</a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-shape fix">
            <img src="/assets/img/bg/footer-shape.png" alt="" />
          </div>
          <div className="footer-shape-two fix">
            <img src="/assets/img/bg/footer-shape1.png" alt="" />
          </div>
          <div className="footer-curve fix">
            <img src="/assets/img/bg/footer-curve.png" alt="" />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer1;
