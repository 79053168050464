import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./Baseurl";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import Sidebar from "./Sidebar";

if (localStorage.token) {
  const token = localStorage.token;
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    localStorage.clear();
    window.location.href = "./login";
  }
}

const Navbar1 = () => {
  const [device, setDevice] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [kycStatus, setKycStatus] = useState();

  useEffect(() => {
    DeviceName();
    getData();
    getGeoInfo();
    getUserData();
  }, []);

  const getUserData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getUser", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        localStorage.setItem("kyc", respp.data[0].kyc_status);
        setKycStatus(respp.data[0].kyc_status);
      }
    });
  };

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryName(data.country_name);
        setCityName(data.city);
      })
      .catch((error) => {});
  };

  const DeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
      return false;
    }
    if (isAndroid) {
      setDevice("Android");
      return false;
    }
    if (isIOS) {
      setDevice("IOS");
      return false;
    }
    if (isDesktop) {
      setDevice("Desktop");
      return false;
    }
  };

  const logout = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const ipData = {
      ip: localStorage.getItem("ip"),
      device: device,
      browser: browserName + " " + browserVersion,
      osName: osName + " " + osVersion,
      cityName: cityName,
      countryName: countryName,
      msg: "Logged Out",
    };

    axios.post(baseUrl + "/auth/loginHistory", ipData, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        localStorage.clear();
        window.location.replace("/login");
      }
    });
  };

  const openSidebar = () => {
    <>
      <div className="sidebar__area sidebar-opened">
        <div className="sidebar__wrapper ">
          <div className="sidebar__close">
            <button className="sidebar__close-btn" id="sidebar__close-btn">
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="sidebar__content">
            <div className="sidebar__logo mb-40">
              <a href="index.html">
                <img src="/assets/img/logo/logo.png" alt="logo" />
              </a>
            </div>

            <div className="mobile-menu fix"></div>
          </div>
        </div>
      </div>
      <div className="body-overlay"></div>
    </>;
  };

  var emaill = localStorage.getItem("email");

  const showKycNotification = () => {
    if (
      window.location.pathname !== "/dashboard" &&
      window.location.pathname !== "/kyc"
    ) {
      if (kycStatus == 0) {
        return (
          <div className="onload-popup">
            <div className="contantbox">
              Your KYC is not done. Kindly complete your KYC. Until you can’t
              create offer. <br />
              <Link to={"/kyc"}>
                <button className="btn btn-primary">
                  {" "}
                  Click here to verify KYC
                </button>
              </Link>
            </div>
          </div>
        );
      }
    } else {
      return;
    }
  };

  return (
    <>
      {/* <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div> */}

      <header className="top-nav ">
        <div className="menu-area menu2" id="header-sticky">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="menu-content">
                  <div className="top-logo">
                    <Link to="/">
                      <img src="/assets/img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="main-menu">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <Link to="/dashboard" className="">
                            Home{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/kyc" className="">
                            KYC{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/whitelist" className="">
                            Whitelist{" "}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="profile_box">
                    <a
                      href=""
                      data-bs-toggle="dropdown"
                      className="dropdown-toggle"
                    >
                      {emaill != undefined ? emaill : ""}{" "}
                      <i className="fas fa-user"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/profile">
                          <i className="fa fa-user-circle" aria-hidden="true"></i>{" "}
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/wallet">
                          <i className="fa fa-wallet"></i> Wallet
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/history">
                          <i className="fa fa-history" aria-hidden="true"></i>{" "}
                          History
                        </Link>
                      </li>
                      <li>
                        <button className="dropdown-item" onClick={logout}>
                          <i className="fa fa-window-close" aria-hidden="true"></i>{" "}
                          Logout{" "}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="side-menu-icon d-lg-none text-end">
                    <a
                      href="javascript:void(0)"
                      className="info-toggle-btn f-right sidebar-toggle-btn"
                    >
                      <i className="fal fa-bars"></i>
                      {/* onClick={openSidebar()} */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </header>
      {showKycNotification()}
    </>
  );
};

export default Navbar1;
