import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const UPRebels = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          class="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/login.jpg")' }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
                UP Rebels
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
            <div className="mblog_info mblog_details">
              <div className="medical_box mb-40 mt-20">
                <div className="row ">
                  <div className="col-md-5 col-lg-5">
                    <img
                      src="assets/img/up_rebls.png"
                      className="img-fluid border_img"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-1 right_content"></div>
                  <div className="col-md-7 col-lg-6 right_content">
                    <div className="row ">
                      <div className="col-md-6 hhh">Team:</div>
                      <div className="col-md-6 ">UP Rebels</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Custodian:</div>
                      <div className="col-md-6 ">
                        Indian Professional Karate Council
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Status:</div>
                      <div className="col-md-6 ">
                        Runner-up Team of UKL Season 2.0
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Valuation:</div>
                      <div className="col-md-6 ">INR 25,00,00,000</div>
                    </div>

                    <div className="row ">
                      <div className="col-md-6 hhh">Team Manager:</div>
                      <div className="col-md-6 ">Sanjeev Singh</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Team Members:</div>
                      <div className="col-md-6 ">
                        <ul>
                          <li>Satya Pal (Captain) India</li>
                          <li>Artsrun Ohanyan (Armenia)</li>
                          <li>Anjali Bhati (India)</li>
                          <li> Rohit Tiwari (India)</li>
                          <li> Ranjeet Kumar (India)</li>
                          <li>Kashif Ahmad (India)</li>
                          <li> Rahul Kumar (India)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default UPRebels;
