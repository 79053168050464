import React, { useState, useEffect } from "react";
import Footer1 from "../Common/Footer1";
import Navbar1 from "../Common/Navbar1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [DOB, setDOB] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cPass, setCPass] = useState("");
  const [oldPassError, setOldPassError] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [cPassError, setCPassError] = useState("");
  const [oldPassCheck, setOldPassCheck] = useState("password");
  const [newPassCheck, setNewPassCheck] = useState("password");
  const [cPassCheck, setCPassCheck] = useState("password");
  // const [invalidImage, setInvalidImage] = useState("");
  const [userImage, setUserImage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("./assets/img/icon2.png");

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getUser();
  }, []);

  const getUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getUser", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setFirstName(respp.data[0].fname);
        setPhone(respp.data[0].number);
        setEmail(respp.data[0].email);
        setDOB(respp.data[0].dob);
        if (
          respp.data[0].dp === null ||
          typeof respp.data[0].dp == "undefined"
        ) {
          var ImageUrl2 = "/assets/img/icon2.png";
        } else {
          var ImageUrl2 = baseUrl + "/static/upload/" + respp.data[0].dp;
        }
        //   setId(respp.data[0].id);
        setUploadedFile(ImageUrl2);
      }
    });
  };
  const handleClick1 = () => {
    if (oldPassCheck === "password") {
      setOldPassCheck("text");
      return false;
    } else {
      setOldPassCheck("password");
    }
  };
  const handleClick2 = () => {
    if (newPassCheck === "password") {
      setNewPassCheck("text");
      return false;
    } else {
      setNewPassCheck("password");
    }
  };
  const handleClick3 = () => {
    if (cPassCheck === "password") {
      setCPassCheck("text");
      return false;
    } else {
      setCPassCheck("password");
    }
  };

  const onChangeImage = (e) => {
    var userImage = e.target.files[0];
    if (!userImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      // setInvalidImage("Please select valid image.");
    }
    setUserImage(userImage);
    setUploadedFile(URL.createObjectURL(e.target.files[0]));
    // this.setState({ uploadedFile: URL.createObjectURL(e.target.files[0]) });
  };

  const handleSubmit = (e) => {
    if (!userImage) {
      // setInvalidImage("Please select a profile image");
      return false;
    }

    const formData = new FormData();
    // formData.append("userId", localStorage.id);
    formData.append("ProfileImage", userImage);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/auth/profileImage", formData, config)
      .then((resp) => {
        var respp = resp.data;
        if (respp.success === true) {
          toast.dismiss();
          toast.success(respp.message);
          return false;
        }
        toast.dismiss();
        toast.error(respp.message);
      });
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "oldPass") {
      if (!value) {
        setOldPassError("Please enter current password");
        setOldPass(value);
        return false;
      }
      setOldPass(value);
      setOldPassError("");
    }

    if (name === "newPass") {
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$";
      if (!value) {
        setNewPass(value);
        setNewPassError("Please enter new password");
        return false;
      }
      if (!value.match(regex)) {
        setNewPass(value);
        setNewPassError(
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
        );
        return false;
      } else {
        setNewPass(value);
        setNewPassError("");
      }
      setNewPass(value);
      setNewPassError("");
    }

    if (name === "cPass") {
      if (!value) {
        setCPassError("Please enter confirm password");
        setCPass(value);
        return false;
      }
      setCPass(value);
      setCPassError("");
    }
  };

  const changePass = (e) => {
    e.preventDefault();

    if (!oldPass && !newPass && !cPass) {
      setOldPassError("Please enter current password");
      setNewPassError("Please enter new password");
      setCPassError("Please enter confirm password");
      return false;
    }

    if (!oldPass || oldPass === undefined) {
      setOldPassError("Please enter current password");
      return false;
    }

    if (newPass === undefined || !newPass) {
      setNewPassError("Please enter new password");
      return false;
    }
    if (cPass === undefined && !cPass) {
      setCPassError("Please enter confirm password");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      oldPass: oldPass,
      newPass: newPass,
      cPass: cPass,
    };

    axios.post(baseUrl + "/auth/changePassword", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        setNewPass("");
        setOldPass("");
        setCPass("");
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  if (userImage) {
    var profilePic = baseUrl + "/static/profile/" + userImage;
  } else {
    var profilePic = "/assets/img/no-image.png";
  }

  return (
    <>
      <Navbar1 />
      {/* <Sidebar1 /> */}
      <main>
        <div class="dashbord_in">
          <div class="container custome-container">
            <h2 class="d_haddign">Profile</h2>

            <div class="row">
              <div className="col-md-3">
                <div className="profile_box2">
                  <input
                    className="upload_input"
                    id="file-upload"
                    type="file"
                    name="userImage"
                    onChange={onChangeImage}
                  />
                  <div id="file-upload-form" className="uploader">
                    {/* <p className="errorsTag"> {this.state.invalidImage} </p> */}
                    <label htmlFor="file-upload" id="file-drag">
                      <div className="profile_img">
                        <img
                          className="img"
                          src={uploadedFile ? uploadedFile : profilePic}
                          // className="hidden"
                          alt=""
                        />
                        <span className="fasi">
                          <i className="fas fa-camera" />
                        </span>
                      </div>
                      <div id="response" className="hidden">
                        <div id="messages" />
                        <progress
                          className="progress"
                          id="file-progress"
                          value={0}
                        >
                          <span>0</span>%
                        </progress>
                      </div>
                    </label>
                  </div>
                  {/* <p className="errorsTag"> {this.state.invalidImage} </p> */}
                  <div>
                    <button
                      class="btn btn-primary fs12 w100px"
                      onClick={handleSubmit}
                    >
                      Change Image
                    </button>
                  </div>
                </div>
              </div>
              {/* <div class="col-md-3">
                <div class="profile_box2">
                  <img
                    class="img"
                    src="/assets/img/aggregator_collaborations.jpg"
                  />
                  {/* <h4 class="mt-3">LiveLamprey828</h4> */}
              {/* <span class="text-white classicDashboardUserWidget__rating"> */}
              {/* <span class="text-success font-weight-bold">+0</span> /{" "}
                    <span class="text-danger font-weight-bold">-0</span> */}
              {/* </span> */}
              {/* </div> */}
              {/* </div> */}
              <div class="col-md-9">
                <div class="white_box">
                  <form>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label>First Name</label>
                        <input
                          type="text"
                          class="form-control"
                          name="fname"
                          placeholder="Enter First Name"
                          minlength="2"
                          maxlength="100"
                          value={firstName}
                          disabled={true}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Email</label>
                        <input
                          type="text"
                          class="form-control"
                          name="email"
                          value={email}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label>Date of Birth</label>
                        <input
                          type="date"
                          class="form-control"
                          name="dob"
                          value={DOB}
                          disabled={true}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="d-flex">Mobile No.</label>
                        <input
                          type="text"
                          class="form-control"
                          name="phone"
                          value={phone}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="white_box">
                  <h4>Change Password</h4>
                  <div class=" col-md-6">
                    <div class="form-group ">
                      <label class="d-flex">Current Password</label>
                      <div class="por">
                        <input
                          type={oldPassCheck}
                          class="form-control"
                          placeholder="Enter Current Password"
                          name="oldPass"
                          value={oldPass}
                          onChange={handleChange101}
                          disabled=""
                        />
                        <span
                          className="password__show eye1"
                          onClick={handleClick1}
                        >
                          {oldPassCheck === "text" ? (
                            <i className="fas fa-eye "></i>
                          ) : (
                            <i className="fas fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                      <div style={{ color: "red" }}>{oldPassError}</div>
                    </div>
                    <div class="form-group ">
                      <label class="d-flex">New Password</label>
                      <div class="por">
                        <input
                          type={newPassCheck}
                          class="form-control"
                          placeholder="Enter New Password"
                          name="newPass"
                          value={newPass}
                          onChange={handleChange101}
                          disabled=""
                        />
                        <span
                          className="password__show eye1"
                          onClick={handleClick2}
                        >
                          {newPassCheck === "text" ? (
                            <i className="fas fa-eye "></i>
                          ) : (
                            <i className="fas fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                      <div style={{ color: "red" }}>{newPassError}</div>
                    </div>
                    <div class="form-group ">
                      <label class="d-flex">Confirm Password</label>
                      <div class="por">
                        <input
                          type={cPassCheck}
                          class="form-control"
                          placeholder="Enter Confirm Password"
                          name="cPass"
                          value={cPass}
                          onChange={handleChange101}
                          disabled=""
                        />
                        <span
                          className="password__show eye1"
                          onClick={handleClick3}
                        >
                          {cPassCheck === "text" ? (
                            <i className="fas fa-eye "></i>
                          ) : (
                            <i className="fas fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                      <div style={{ color: "red" }}>{cPassError}</div>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary fs12 w100px"
                    onClick={changePass}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </main>

      <Footer1 />
    </>
  );
};

export default Profile;
