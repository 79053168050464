import React, { useState, useEffect } from "react";
import Footer1 from "../Common/Footer1";
import Navbar1 from "../Common/Navbar1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const SupportEarn = () => {
  const [playerID, setPlayerID] = useState("");
  const [sponsorship, setSponsorship] = useState("");
  const [amount, setAmount] = useState("");
  const [backPrice, setBackPrice] = useState("");
  const [leftPrice, setLeftPrice] = useState("");
  const [rightPrice, setRightPrice] = useState("");
  const [chestPrice, setChestPrice] = useState("");
  const [player, setPlayer] = useState("");
  const [data, setData] = useState([]);
  const [playerName, setPlayerName] = useState("");
  const [dob, setDob] = useState("");
  const [description, setDescription] = useState("");
  const [jerseyNo, setJerseyNo] = useState("");
  const [gStatus, setGStatus] = useState(false);
  const [playerImage, setPlayerImage] = useState(null);
  const [errorPlayer, setErrorPlayer] = useState("");
  const [jerseyImage, setJerseyImage] = useState(null);
  const [actualJerseyImage, setActualJerseyImage] = useState(false);
  const [actualPlayerImage, setActualPlayerImage] = useState(false);
  const [btn, setBtn] = useState(false);

  const defaultImage = "assets/img/support_earn.jpg";

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getSponsorshipData();
  }, []);

  const getSponsorshipData = () => {
    axios.post(baseUrl + "/auth/getSponsorshipData", {}).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setData(respp.data);
        setGStatus(true);
      }
    });
  };

  const playerDropdown = () => {
    const html = [];
    html.push(<option value="">Select</option>);
    var sortedData = data.sort();

    data.sort(function (a, b) {
      if (a.player_name < b.player_name) {
        return -1;
      }
      if (a.player_name > b.player_name) {
        return 1;
      }
      return 0;
    });

    sortedData.map(function (value, i) {
      html.push(<option value={value.id}>{value.player_name}</option>);
    });
    return (
      <select class="form-control" name="player" onChange={handleChangePlayer}>
        {html}
      </select>
    );
  };

  const handleChangePlayer = (e) => {
    let { name, value } = e.target;
    if (name === "player") {
      setSponsorship("");
      setAmount("");
      if (value) {
        setErrorPlayer("");
        const data = {
          player_id: value,
        };

        axios.post(baseUrl + "/auth/getPlayerById", data).then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            setPlayerID(respp.data[0].id);
            setPlayerImage(respp.data[0].player_image);
            setPlayerName(respp.data[0].player_name);
            setDescription(respp.data[0].description);
            setDob(respp.data[0].dob);
            setJerseyNo(respp.data[0].jersey_no);
            setJerseyImage(respp.data[0].jersey_image);
            setChestPrice(respp.data[0].chest_price);
            setBackPrice(respp.data[0].back_price);
            setLeftPrice(respp.data[0].left_price);
            setRightPrice(respp.data[0].right_price);
            setActualJerseyImage(
              baseUrl + "/static/upload/" + respp.data[0].jersey_image
            );
            setActualPlayerImage(
              baseUrl + "/static/upload/" + respp.data[0].player_image
            );

            console.log(
              "firstttttpl",
              baseUrl + "/static/upload/" + respp.data[0].player_image
            );
            console.log(
              "firstttttjers",
              baseUrl + "/static/upload/" + respp.data[0].jersey_image
            );
            // setCountryData(respp.data)
            console.log("plimg", respp.data[0].player_image);
            console.log("jersimg", respp.data[0].jersey_image);
            return false;
          }
        });
        return false;
      }

      if (!value) {
        setErrorPlayer("Please select a player");
        setPlayerImage(null);
        setPlayerName("");
        setDescription("");
        setDob("");
        setJerseyNo("");
        setJerseyImage(null);
        setChestPrice("");
        setBackPrice("");
        setLeftPrice("");
        setRightPrice("");
        setSponsorship("");
        setAmount("");
        return false;
      }
    }
    setPlayer("");
  };

  const handleChangeSponsorship = (e) => {
    let { name, value } = e.target;

    if (name === "sponsorship") {
      if (value === "Chest") {
        setAmount(chestPrice);
        setSponsorship(value);
        return false;
      }
      if (value === "Back") {
        setAmount(backPrice);
        setSponsorship(value);
        return false;
      }
      if (value === "Left Shoulder") {
        setAmount(leftPrice);
        setSponsorship(value);
        return false;
      }
      if (value === "Right Shoulder") {
        setAmount(rightPrice);
        setSponsorship(value);
        return false;
      }
    }
  };

  const pay = (e) => {
    e.preventDefault();

    if (playerName === "" || !playerName || playerName === undefined) {
      toast.error("Please select player");
      return false;
    }

    if (sponsorship === "" || !sponsorship || sponsorship === undefined) {
      toast.error("Please select sponsorship");
      return false;
    }

    if (!amount) {
      toast.error("Please select again");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let data = {
      player_name: playerName,
      sponsorship: sponsorship,
      player_id: playerID,
      amount: amount,
    };

    axios.post(baseUrl + "/auth/supportEarn", data, config).then((resp) => {
      setBtn(true);
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 3500);
        return false;
      }
      setBtn(false);
      toast.dismiss();
      toast.error(respp.message);
    });
  };

  return (
    <>
      <Navbar1 />
      {/* <Sidebar1 /> */}
      <main>
        <div className="dashbord_in">
          <div className="container custome-container">
            <h2 className="d_haddign">Support & Earn</h2>

            <div className="white_box">
              <div className="row">
                <div className="form-group col-md-4">
                  <label className="h5">
                    <b>Select Player</b>
                  </label>
                  {playerDropdown()}
                </div>
              </div>
              <div className="white_box_in">
                <div className="row ">
                  <div className="col-md-4">
                    <div className="form-group mb-md-0">
                      <img
                        src={
                          playerImage === null
                            ? defaultImage
                            : actualPlayerImage
                        }
                        alt="Player_Image bg-white"
                        className="img-fluid play_pay_img"
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-8">
                    <div className="white_box mb-0">
                      <p>Player Name : {playerName}</p>
                      <p>
                        Date of Birth :{" "}
                        {dob === "" ? "" : moment(dob).format("ll")}
                      </p>
                      <p>Jersey No : {jerseyNo}</p>
                      <p>Description : {description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-md-5">
                <div className="form-group col-md-6">
                  <div className="form-group">
                    <img
                      src={
                        jerseyImage === null ? defaultImage : actualJerseyImage
                      }
                      alt="Jersey_Image"
                      className="img-fluid play_pay_img"
                    />
                  </div>
                </div>

                <div className="form-group col-md-6 ">
                  <b>Select Sponsorship</b>
                  <select
                    className=" form-control mb-3"
                    name="sponsorship"
                    value={sponsorship}
                    onChange={handleChangeSponsorship}
                  >
                    <option value="">SELECT SPONSORSHIP</option>
                    <option value="Back">BACK</option>
                    <option value="Chest">CHEST</option>
                    <option value="Left Shoulder">LEFT SHOULDER</option>
                    <option value="Right Shoulder">RIGHT SHOULDER</option>
                  </select>
                  <div className="form-group  h4  pt-2">
                    <label>Amount To Be Paid</label>
                  </div>
                  <div className="form-group  h4 ">
                    <span className="h2 ">
                      <b>{amount !== "" ? amount + "$" : ""}</b>
                    </span>
                  </div>
                  <div className="form-group  text-right">
                    <button
                      className="btn btn_man"
                      onClick={pay}
                      disabled={btn}
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </main>

      <Footer1 />
    </>
  );
};

export default SupportEarn;
