import React, { useState, useEffect, useRef } from "react";
import Navbar1 from "../Common/Navbar1";
import Footer1 from "../Common/Footer1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
// import {
//   EmailShareButton,
//   FacebookShareButton,
//   HatenaShareButton,
//   InstapaperShareButton,
//   LineShareButton,
//   LinkedinShareButton,
//   LivejournalShareButton,
//   MailruShareButton,
//   OKShareButton,
//   PinterestShareButton,
//   PocketShareButton,
//   RedditShareButton,
//   TelegramShareButton,
//   TumblrShareButton,
//   TwitterShareButton,
//   ViberShareButton,
//   VKShareButton,
//   WhatsappShareButton,
//   WorkplaceShareButton,
// } from "react-share";

const Refer = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const [code, setCode] = useState("");

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getUser();
  }, []);

  const getUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getUser", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setCode(respp.data[0].user_referral_code);
      }
    });
  };

  function copyToClipboard() {
    navigator.clipboard.writeText(code);
    setCopySuccess("Copied!");
  }

  return (
    <>
      <Navbar1 />
      {/* <Sidebar1 /> */}
      <main>
        <div className="dashbord_in">
          <div className="container custome-container">
            <h2 className="d_haddign">Refer & Earn</h2>

            <div className="white_box">
              <h4> Referral 10% incentive </h4>
              <h5>
                <b> REFER CODE</b>{" "}
              </h5>
              <div style={{ color: "green" }}>{copySuccess}</div>
              <div className="row">
                <div className="form-group col-6 col-md-5">
                  <input
                    type="tel"
                    autocomplete="tel"
                    placeholder="Refer Id"
                    name="text"
                    className="form-control"
                    ref={textAreaRef}
                    value={code}
                  />
                </div>
                <div className="col-md-5">
                  <button
                    className="btn btn_man"
                    onClick={() => copyToClipboard()}
                  >
                    Copy
                  </button>
                  <button className="btn btn_man">Share</button>
                </div>
              </div>
              <b className="mt-0">Terms & Conditions</b>
              <ul className="dit_ul mb-3">
                <li>
                  The photo/scan must be clear, in color and uncompressed.
                </li>
                <li>The photo/scan must be not edited and not cropped.</li>
                <li>
                  The document must be valid at least 3 months after the
                  verification submission date.
                </li>
                <li>
                  The full name and date of birth you provided in the "Personal
                  information" section{" "}
                </li>
                <li>should exactly match the ones in your document.</li>
                <li>Min size 10 Kb / Max size 1 Mb.</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </>
  );
};

export default Refer;
