import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const ApplicationProcess = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
          <main>
    
		 <div
          className="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/login.jpg")' }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
              Process of Application

              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
             <h3 className="mblog__title mb-15">Process of Application</h3> 
            <p>	General overview of the steps involved in the application and payment process for Master Franchise.
</p>
  
			
			<ul className="ul_content">
			   <li><b>Research and gather information: </b> Begin by researching the product, service, or program you wish to apply for. Understand the eligibility criteria, requirements, and any associated fees or
costs.</li> 
			   <li><b> Apply at info@ukl.world:</b> Send your Letter of Intent (LOI) to info@ukl.world</li> 
			   <li><b>Fill out the application form: </b> Complete the application form accurately and thoroughly. Provide all the requested details, ensuring that the information is correct and up to date. Double- check for any errors or omissions before submitting the form.</li> 
			   <li><b> Review and submit the application:</b> Take a final review of your completed application form to ensure its accuracy. If applicable, attach any supporting documents as required. Once you are satisfied with the application, submit it through the designated method, such as online submission, email, or physical submission.</li> 
			   <li><b> Await confirmation or processing:</b> After submitting the application, you will generally receive a confirmation of receipt or an acknowledgment email. The organization will then process your application based on their internal procedures and timeline.</li> 
			   <li><b>Payment options:  </b>If there are any fees associated with the application, you will typically have various payment options available. These may include credit/debit card payments, bank transfers, online payment gateways, or other specified methods.
</li> 
			   <li><b>Make the payment:  </b>Select the appropriate payment method and follow the provided instructions to complete the payment process. Ensure that you provide accurate payment details and verify the transaction before finalizing it. Some applications may require upfront payment, while others may request payment upon approval or at a later stage.
</li> 
			   <li><b> Confirmation of payment:</b> After making the payment, you will generally receive a confirmation of payment, which may be in the form of a receipt, email, or transaction confirmation. Keep this documentation for your records.
</li> 
			   <li><b> Master Franchise Agreement:</b> The Master Franchise Agreement shall be signed by the authorised representative of the applicant organisation and the Ultimate Karate Council d.o.o., Belgrade, Serbia.</li> 
			</ul>
			<p>
			 
			</p>
			
          </div>
        </div>
      </main>
     
      <Footer />
    </>
  );
};

export default ApplicationProcess;
