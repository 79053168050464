import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div className="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/about.jpg")' }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                About Us
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <p>
             Karate has come a long way from its origins as a means of self-defense against predators. Its evolution over centuries has led to the development of various styles and forms, with each emphasizing
different techniques and principles. The art has been influenced by Indian and Chinese martial arts, and later, by Japanese styles.
            </p>
            <p>
              In the 1960s and 1970s, karate gained popularity around the world as a sport, and various organizations were formed to regulate competitions and championships. The World Body of Karate was
one such organization that aimed to promote the sport and establish a standard set of rules. The first World Karate Championship in 1970 marked a significant milestone in the sport's history and
paved the way for its growth and development.
            </p>
            <p>
              However, as with any sport, there have been disagreements and differing opinions on the direction karate should take. Some groups promote a pure sports form of karate, while others believe in
preserving the art's traditional roots. The World Fudokan Federation and Ultimate Karate Council are examples of organizations that have taken different approaches to the sport.
            </p>
            <p>
              The Ultimate Karate Council, led by Sensei Rajeev Sinha, has taken a unique approach to the sport by introducing a new tournament format. In this format, individual matches are converted into
team matches, and the rules emphasize power, speed, and control. This new system, termed Ultimate Karate, aims to bring together the best aspects of traditional karate with modernity, providing a
thrilling and entertaining experience for participants and spectators alike
            </p>    
			<p>
              The demands of Ultimate Karate are high, with participants requiring extraordinary power, lightning speed, and outstanding control. It's a bit like driving a high-speed car at maximum speed but
returning home safely. The emphasis on these skills creates a competitive environment that challenges participants to push their limits and reach their full potential.
            </p>
			<p>
             In conclusion, karate has come a long way from its humble beginnings as a means of self-defense. It has evolved over centuries, and different organizations have emerged with different approaches
to the sport. Ultimate Karate is one such approach, emphasizing power, speed, and control in a team format that lasts for 45-minute telecast time. We have successfully completed two seasons on
Discovery Channel’s Dsports and SONY TEN1. Now, UKL is set for global broadcast in over 100 countries.
            </p>

            <h2 className="text-center mb-4 mblog__title mt-5">
              World of Sports
            </h2>
            <div className="row">
              <div className="col-md-6">
                <div className="aboutbox aboutbox_color">
                  Amateur Sports (Election based)
                </div>
                <div className="aboutbox">
                  IOC - International Olympic Committee
                </div>
                <div className="aboutbox">
                  International / National Federations
                </div>
                <div className="aboutbox">Olympic Game is the pinnacle</div>
                <div className="aboutbox">Government FUNDING</div>
              </div>
              <div className="col-md-6">
                <div className="aboutbox aboutbox_color">
                  Professional Sports (Equity Based)
                </div>
                <div className="aboutbox">
                  World Professional Bodies or independent companies promoting a
                  particular sports
                </div>
                <div className="aboutbox">
                 No Government Funding
                </div>
                <div className="aboutbox">
                  Maximum Prize money is the pinnacle of aspiration
                </div>
                <div className="aboutbox">Market driven eco-system</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bg_img mt-30"
          data-background="assets/img/vfok.jpg"
          style={{ backgroundImage: 'url("assets/img/vfok.jpg")' }}
        >
          <div className="container custome-container">
            <div
              className="section-wrap-two text-center wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <h3 className="section-title-two  mb-20 white-color">
                Comparison between Amateur & Professional Karate
              </h3>
            </div>

            <div className="row">
              <div className="col-md-3"> </div>
              <div className="col-md-6">
                {" "}
                <div className="aboutbox aboutbox_color ">
                  {" "}
                  <span className="h3">KARATE WORLD</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutbox aboutbox_color">AMATEUR KARATE</div>
                <div className="aboutbox ">
                  Over 100 World Bodies Governing Different Formats
                </div>
                <div className="aboutbox ">
                  Unification under One Body under boring “Touch and Return
                  Rules”
                </div>
                <div className="aboutbox ">
                  Extremely monotonous Individual sport. Failure of mass appeal
                  results in emergence of UFC, MMA etc.
                </div>
                <div className="aboutbox ">
                  No innovation in rules other than few ornamental changes
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutbox aboutbox_color">
                  PROFESSIONAL KARATE
                </div>
                <div className="aboutbox ">
                  Only ONE World Professional Body UKC
                </div>
                <div className="aboutbox aboutox_color">
                  Structured along Equity
                </div>
                <div className="aboutbox ">UKL is Unique Team Format</div>
                <div className="aboutbox ">
                  Created after years of Research and Development and protected
                  under Intellectual Property Rights laws
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <h2 className="text-center mb-4 mblog__title mt-5">
              WORLD KARATE STRUCTURE
            </h2>
            <div className="row">
              <div className="col-md-6">
                <div className="aboutbox aboutbox_color">Amateur Karate</div>
                <div className="aboutbox">
                  Sports form of karate is governed by World Karate Federation
                  that follows “touch and withdraw” rule to make sport very safe
                </div>
                <div className="aboutbox">
                  Including WFF, there are 8 major World Bodies governing
                  different forms of Amateur Karate
                </div>
                <div className="aboutbox">
                  There are 100+ World Bodies that govern different styles of
                  Karate
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutbox aboutbox_color">
                  Professional Karate
                </div>
                <div className="aboutbox">
                  Ultimate Karate Council (UKC) Equity Based World Structure
                </div>
                <div className="aboutbox">National Bodies</div>
                <div className="aboutbox">
                  Ultimate Karate Council (UKC) that conducts unique tournament
                  format under name UKL Ultimate Karate League worldwide
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog_area pt-80 pb-70 bglight">
          <div className="container custome-container">
            <h2 className="text-center mb-4 mblog__title mt-5">
              UKC - Ultimate Karate Council
            </h2>
            <p>
              UKC - UlUmate Karate Council is the World entity that controls UKL
              Ultimate Karate League worldwide. It is incorporated in Belgrade,
              Serbia with Company registraUon number: 21838209.{" "}
            </p>
            <h3>CHALLENGES BEFORE KARATE TILL 2019 : </h3>
            <p>
              Karate was NOT a spectator Sport and failed in attracting general
              public to stadiums. Karate competitions have become too boring from
              broadcast perspective. Karate needed complete OVERHAUL in its
              competitions rules that would attract spectators and enhance fan
              engagement.{" "}
            </p>
            <h3>SOLUTIONS :</h3>
            <p>
              “Power, Speed, Control and Variations” are the FOUR fundamental
              principles that guided the development of new technical rules of
              competitions. Sensei Rajeev Sinha researched and developed new
              technical rules that was initially protested by the World community
              but later accepted as the only solution. Sensei termed this as
              ULTIMATE KARATE and is the owner of Intellectual Property Rights.{" "}
            </p>
            <h3>
              Research & Development and Intellectual Property Rights Protection {" "}
            </h3>
            <p>
              New competition Rules, Copyrights and Trade Marks are developed and
              protected under IPR Protection laws. While the Rule Book is
              copyrighted, the Trade Marks of UKL Ultimate Karate League and
              CuppaSchoola are registered and protected from commercial exploits
              by any third party.{" "}
            </p>

            <h3>TESTED, TRIED AND APPLIED </h3>
            <p>
              The new invented match format was successfully tested before its
              launch on Discovery Channel’s Dsport (Eurosport) for 18 weeks
              telecast in 2019. Its success was noticed by all including KPMG
              that carried UKL success story in and as Digital Future of India
              (August 2019 Report, page 210). The year 2020 had zero noticed
              because of prolonged COVID19 lock-down. 2021 witnessed super
              successful event telecast LIVE on Sony TEN1 sports network.{" "}
            </p>
            <h3>WORLD ENTITY FOR WORLD LEAGUE </h3>
            <p className="mb-3">
              It was strongly realised that amateur world body can NOT control
              professional sport and there was an urgent need to setup World
              enUty to officially carry forward the World League under license
              agreement with the IPR owner. Accordingly, in consultaUons with
              the World Body WFF, the ULTIMATE KARATE COUNCIL (UKC) was
              established on 28 September 2022 in Belgrade, Serbia.{" "}
            </p>

            <h3>The idea behind this venture: </h3>

            <ul className="p-0 m-0 ul_content">
              <li>
                Sport that entertains spectators and enhances fan engagements.{" "}
              </li>
              <li>
                Establish aspirational connect amongst the existing and future
                karate practitioners. There are over 190 million practitioners of
                karate around the world. India alone boasts of over 40 million
                karate practitioners who pay to learn and compete. However, in
                absence of top end events, there is significant number of
                drop-outs as the current amateur format does not provide placorm
                for trainees dream of becoming Messy or Ronaldos of karate.{" "}
              </li>
              <li>
                UKL – Ultimate Karate League, one at the world-class senior event
                is the TradeMarked vertical that intends to create new Karate
                Culture worldwide.{" "}
              </li>
              <li>
                UKL World League is set to kick-start  with 6
                country teams in 2023. Every year, there shall be an addition of
                2 new countries.{" "}
              </li>
              <li>
                UKC UlUmate Karate Council becomes the World entity that controls
                UKL World League.{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
            <h2 className="text-center mb-4 mblog__title mt-5">
              UKL - Ultimate Karate League
            </h2>
            <p>
              With over 190 million practitioners worldwide, Karate is very fast
              transforming itself into professional sport under the guidance of
              three legendary masters of the World - Sensei ILIJA JORGA, Sensei
              Vladimir JORGA and Sensei Rajeev Sinha.{" "}
            </p>

            <p>
              {" "}
              UKL Ultimate Karate League with unique competitions rule has already
              attracted huge spectators across the globe. The founders have
              successfully run the pilot project in India that attracted over
              10,000 spectators who were simply mesmerized by the new fighUng
              rules that changed 3 minutes individual match to 45 minutes TEAM
              match. The inaugural Season 1 on Discovery Channel’s Dsport for 18
              weeks was a Superhit program in martial arts genre. The 2nd Season
              on SONY TEN1 network attracted viewers from South Asia in LIVE
              telecast for 10 days.{" "}
            </p>
            <p>
              {" "}
              In addition to the country League in India, now the event goes
              global with WORLD LEAGUE to be held across several months of every
              calendar year from 2023.
            </p>

            <h3 className="mb-3">
              For Match format and competitions Rules, please CLICK here
             {" "}
            </h3>
            <h3>The idea behind this venture:</h3>

            <ul className="p-0 m-0 ul_content">
              <li>
                Sport that entertains spectators and enhances fan engagements.{" "}
              </li>
              <li>
                Establish aspirational connect amongst the Existing and future
                karate practitioners. There are over 190 million practitioners of
                karate around the world. India alone boasts of over 40 million
                karate practitioners who pay to learn and compete. However, in
                absence of top end events, there is significant number of
                drop-outs as the current amateur format does not provide platform
                for trainees dream of becoming Messy or Ronaldos of karate.{" "}
              </li>
              <li>
                UKL – Ultimate Karate League, one at the world-class senior event
                is the TradeMarked vertical that intends to create new Karate
                Culture worldwide.{" "}
              </li>
              <li>
                UKL World League is set to kick-start  with 6
                country teams in 2023. Every year, there shall be an addiUon of
                2 new countries.{" "}
              </li>
              <li>
                UKC Ultimate Karate Council becomes the World entity that controls
                UKL World League.{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 bglight">
          <div className="container custome-container">
            <div className="mblog_info mblog_details">
              <h6 className="mblog__title mb-15">World Governing Council</h6>
              <div className="medical_box mb-40 mt-20">
                <div className="row ">
                  <div className="col-md-3 col-lg-2">
                    <img
                      src="assets/img/sensei-dr.-prof.-Ilija-jorga.jpg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h3>Sensei Dr. (Prof.) llija Jorga</h3>
                    <p>
                      The Living Legend Sensei Ilija Jorga, 10th Dan, University
                      Professor of Medicine, with over 800 joint scientific
                      research, having started Karate in 1959 and played
                      instrumental role in popularization of karate in more than
                      120 countries, Sensei Ilija Jorga founded FUDOKAN in 1980.
                      A protégé of O-Sensei Hidetaka Nishiyama, Sensei llija is
                      Chairman of Board of Governors of UKL.
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="medical_box mb-40 mt-20">
                <div className="row ">
                  <div className="col-md-3 col-lg-2">
                    <img
                      src="assets/img/vladimir-jorga.jpg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h3>Sensei Dr. (Prof.) Vladimir Jorga</h3>
                    <p>
                      Sensei Vladimir Jorga, 10th Dan, University Professor of
                      Medicine, with over 800 joint scientific research, started
                      Karate in 1957 and played instrumental role in
                      popularization of karate worldwide. Sensei Vladimir
                      remained undisputed leader. Currently he is Dean of ECPD
                      under UNO. A protégé of Sensei Taiji Kase and Sensei
                      Hidetaka Nishiyama, Sensei Vladimir is the co-chair of
                      Board of Governors of UKL.
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="medical_box mb-40 mt-20">
                <div className="row ">
                  <div className="col-md-3 col-lg-2">
                    <img
                      src="assets/img/rajeev-sinha.jpg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h3>Sensei Rajeev Sinha</h3>
                    <p>Managing Director - UKL - UKC</p>
                    <p>
                      Life President of the World Fudokan Federation, IPR Owner
                      of the UKL Ultimate Karate League, 1984 World Cup
                      Silver-medaiist, Managing Director - UKL - Ultimate Karate
                      Council (UKC), is the IPR owner of the format. With Five decades
 of experience in Karate, Sensei is a visiting
                      professor to a number of European and North American
                      a and is countries renowned for his research and development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AboutUs;
