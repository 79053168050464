import React, { useState, useEffect } from "react";
import Navbar1 from "../Common/Navbar1";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CountryDropdown } from "react-country-region-selector";

const ApplyJobs = () => {
  let jobId = useParams();
  let job_id = jobId.id;

  const decodedJob_id = atob(job_id);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [experience, setExperience] = useState("");
  const [resume, setResume] = useState("");
  const [userUploadResume, setUserUploadResume] = useState("");
  const [doc, setDoc] = useState("");
  const [userUploadDoc, setUserUploadDoc] = useState("");
  const [btn, setBtn] = useState(false);

  // errors //

  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorStates, setErrorStates] = useState("");
  const [errorZipcode, setErrorZipcode] = useState("");
  const [errorExperience, setErrorExperience] = useState("");
  const [errorResume, setErrorResume] = useState("");
  const [errorDoc, setErrorDoc] = useState("");

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
  }, []);

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    // $("input[type=number]").on("mousewheel", function (e) {
    //   $(e.target).blur();
    // });

    if (name === "Name") {
      if (!value) {
        setErrorName("Please enter name");
        setName(value);
        return false;
      }
      setErrorName("");
      setName(value);
    }
    if (name === "Phone") {
      let value = e.target.value.trim();
      if (!value) {
        setErrorPhone("Please enter mobile number");
        setPhone(value);
        return false;
      }
      var regexNum = "^[0-9]*$";
      if (!value.match(regexNum)) {
        setErrorPhone("Please enter only numeric value");
        setPhone(value);
        return false;
      }
      if (value.length > 10) {
        setErrorPhone("Mobile number must be 10 digits");
        setPhone(value);
        return false;
      }
      if (value.length < 10) {
        setErrorPhone("Mobile number must be 10 digits");
        setPhone(value);
        return false;
      }
      setErrorPhone("");
      setPhone(value);
    }
    if (name === "Email") {
      if (!value) {
        setErrorEmail("Please enter email");
        setEmail(value);
        return false;
      }
      if (value.includes(" ")) {
        setErrorEmail("No blank space allowed");
        setEmail(value);
        return false;
      }
      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
      if (!value.match(emailReg)) {
        setErrorEmail("Please enter a valid email");
        setEmail(value);
        return false;
      } else {
        setErrorEmail("");
      }
      setEmail(value);
      setErrorEmail("");
    }
    if (name === "State") {
      if (!value) {
        setErrorStates("Please enter state");
        setStates(value);
        return false;
      }
      setErrorStates("");
      setStates(value);
    }
    if (name === "City") {
      if (!value) {
        setErrorCity("Please enter city");
        setCity(value);
        return false;
      }
      setErrorCity("");
      setCity(value);
    }
    if (name === "Zipcode") {
      if (!value) {
        setErrorZipcode("Please enter zipcode");
        setZipcode(value);
        return false;
      }
      var regexZipcode = "^[0-9]*$";
      if (!value.match(regexZipcode)) {
        setErrorZipcode("Please enter only numeric value");
        setZipcode(value);
        return false;
      }
      if (value.length < 5) {
        setErrorZipcode("Please enter minimum 5 digits");
        setZipcode(value);
        return false;
      }
      if (value.length > 10) {
        setErrorZipcode("Please enter valid zipcode 5-10 digits");
        setZipcode(value);
        return false;
      }
      var regexZipcode1 = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
      if (!value.match(regexZipcode1)) {
        setErrorZipcode("Please enter valid input");
        setZipcode(value);
        return false;
      }
      if (value <= 0) {
        setErrorZipcode("Please enter valid input");
        setZipcode(value);
        return false;
      } else {
        setErrorZipcode("");
        setZipcode(value);
      }

      setErrorZipcode("");
      setZipcode(value);
    }
    if (name === "Experience") {
      if (!value) {
        setErrorExperience("Please enter experience");
        setExperience(value);
        return false;
      }
      setErrorExperience("");
      setExperience(value);
    }
  };

  const onChangeCountry = (e) => {
    if (!e) {
      setErrorCountry("Please select country");
      setCountry(e);
      return false;
    } else {
      setErrorCountry("");
      setCountry(e);
    }
  };

  const handleFile = (e) => {
    e.preventDefault();
    setResume(e.target.files[0]);
    var cv = e.target.files[0];
    setUserUploadResume(e.target.files[0].name);
    if (cv === false) {
      setUserUploadResume(false);
      setErrorResume("Please select pdf file for resume");
      return false;
    }
    // if (!cv.name.match(/\.(pdf|doc)$/)) {
    //   setUserUploadResume(false);
    //   setErrorResume("Please select valid pdf file for resume");
    //   return false;
    // }
    if (cv.size >= 2000000 || cv.size < 10000) {
      setErrorResume("Please select pdf file that is between 100kb to 2 mb");
      return false;
    }
    // if (IdentityPic.size < 50000) {
    //     toast.error("File Size is less than 500KB")
    //     return false;
    // }
    setErrorResume("");
    setUserUploadResume(e.target.files[0].name);
  };

  const handleFileDocument = (e) => {
    e.preventDefault();
    setDoc(e.target.files[0]);
    var cv = e.target.files[0];
    setUserUploadDoc(e.target.files[0].name);
    if (cv === false) {
      setUserUploadDoc(false);
      setErrorDoc("Please select pdf file for document");
      return false;
    }
    // if (!cv.name.match(/\.(pdf|doc)$/)) {
    //   setUserUploadDoc(false);
    //   setErrorDoc("Please select valid pdf file for document");
    //   return false;
    // }
    if (cv.size >= 2000000 || cv.size < 10000) {
      setErrorDoc("Please select pdf file that is between 100kb to 2 mb");
      return false;
    }
    setErrorDoc("");
    setUserUploadDoc(e.target.files[0].name);
  };

  const submit = (e) => {
    e.preventDefault();
    // var extentionType = /(\.pdf|\.doc)$/i;
    var regexZipcode = "^[0-9]*$";
    var regexZipcode1 = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (
      !name &&
      !phone &&
      !email &&
      !country &&
      !city &&
      !states &&
      !zipcode &&
      !experience &&
      !resume
    ) {
      setErrorName("Please enter name");
      setErrorPhone("Please enter mobile number");
      setErrorEmail("Please enter email");
      setErrorCountry("Please select country");
      setErrorStates("Please enter state");
      setErrorCity("Please enter city");
      setErrorZipcode("Please enter zipcode");
      setErrorExperience("Please enter experience");
      setErrorResume("Please attach resume");
      return false;
    }

    if (!name) {
      setErrorName("Please enter name");
      return false;
    }

    if (!phone) {
      setErrorPhone("Please enter mobile number");
      return false;
    }
    var regexNum = "^[0-9]*$";
    if (!phone.match(regexNum)) {
      setErrorPhone("Please enter only numeric value");
      return false;
    }
    if (phone.length > 10) {
      setErrorPhone("Mobile number must be 10 digits");
      return false;
    }
    if (phone.length < 10) {
      setErrorPhone("Mobile number must be 10 digits");
      return false;
    }

    if (!email) {
      setErrorEmail("Please enter email");
      return false;
    }
    if (email.includes(" ")) {
      setErrorEmail("No blank space allowed");
      return false;
    }
    if (!email.match(emailReg)) {
      setErrorEmail("Please enter a valid email");
      return false;
    }

    if (country === "" || !country || country === undefined) {
      setErrorCountry("Please select country");
      return false;
    }

    if (!states) {
      setErrorStates("Please enter state");
      return false;
    }

    if (!city) {
      setErrorCity("Please enter city");
      return false;
    }

    if (!zipcode) {
      setErrorZipcode("Please enter zipcode");
      return false;
    }
    if (!zipcode.match(regexZipcode)) {
      setErrorZipcode("Please enter only numeric value");
      return false;
    }
    if (!zipcode.match(regexZipcode1)) {
      setErrorZipcode("Please enter valid input");
      return false;
    }
    if (zipcode.length < 5) {
      setErrorZipcode("Please enter minimum 5 digits");
      return false;
    }
    if (zipcode.length > 10) {
      setErrorZipcode("Please enter valid 5-10 digits");
      return false;
    }

    if (!experience || experience === undefined || experience === "") {
      setErrorExperience("Please enter experience");
      return false;
    }

    if (resume === false) {
      setErrorResume("Please attach resume");
      return false;
    }
    //  (!resume.match(/\.(pdf|doc)$/))
    // if (!extentionType.exec(resume)) {
    //   setErrorResume("Please select valid pdf file for resume");
    //   return false;
    // }
    if (resume.size >= 2000000 || resume.size < 10000) {
      setErrorResume("Please select pdf file that is between 100kb to 2 mb");
      return false;
    }

    // if (!document.name.match(/\.(pdf|doc)$/)) {
    //   setErrorResume("Please select valid pdf file for document");
    //   return false;
    // }

    if (document.size >= 2000000 || document.size < 10000) {
      setErrorResume("Please select pdf file that is between 100kb to 2 mb");
      return false;
    }

    var IdentityPic = resume;
    var IdentityPic2 = doc;
    const formData = new FormData();
    formData.append("jobId", decodedJob_id);
    formData.append("Resume", IdentityPic);
    formData.append("Document", IdentityPic2);
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("country", country);
    formData.append("states", states);
    formData.append("city", city);
    formData.append("zipcode", zipcode);
    formData.append("experience", experience);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/applyJobs", formData, config).then((resp) => {
      setBtn(true);
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 2000);
        return false;
      } else {
        setBtn(false);
        toast.dismiss();
        toast.error(respp.message);
      }
    });
  };

  return (
    <>
      <Navbar1 />
      <Sidebar />

      <div className="dashbord_in">
        <div className="container custome-container">
          <h2 className="d_haddign">Apply for Job</h2>
          <div className="white_box">
            <div className="row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  placeholder="Name"
                  name="Name"
                  className="form-control"
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorName}</div>
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  placeholder="Phone no"
                  name="Phone"
                  className="form-control"
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorPhone}</div>
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  placeholder="Email"
                  name="Email"
                  className="form-control"
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorEmail}</div>
              </div>
              <div className="form-group col-md-6">
                <CountryDropdown
                  className="form-control"
                  name="Country"
                  value={country}
                  onChange={(e) => onChangeCountry(e)}

                  //   disabled={btnRes}
                />
                <div style={{ color: "red" }}>{errorCountry}</div>
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder="State"
                  name="State"
                  className="form-control"
                  value={states}
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorStates}</div>
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder="City"
                  name="City"
                  className="form-control"
                  value={city}
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorCity}</div>
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder=" Pincode/Zipcode"
                  name="Zipcode"
                  className="form-control"
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorZipcode}</div>
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  placeholder="Experience"
                  name="Experience"
                  className="form-control"
                  onChange={handleChange101}
                />
                <div style={{ color: "red" }}>{errorExperience}</div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Upload Resume</label>
                <div id="file-upload-form" className="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept=".pdf,.doc"
                    onChange={handleFile}
                  />

                  <label for="file-upload" id="file-drag">
                    {userUploadResume}
                    {/* <img
                        id="file-image"
                        src={userUploadResume != false ? userUploadResume : pic}
                        alt="Preview"
                        // className="hidden"
                      /> */}
                    <div id="start">
                      <span className="fasi">
                        <i className="fas fa-upload"></i>
                      </span>
                      <div>
                        {userUploadResume !== false ? "" : "Upload Resume"}
                      </div>
                      <div id="notimage" className="hidden">
                        Please select resume
                      </div>
                    </div>
                    <div id="response" className="hidden">
                      <div id="messages"></div>
                      <progress
                        className="progress"
                        id="file-progress"
                        value="0"
                      >
                        <span>0</span>%
                      </progress>
                    </div>
                  </label>
                  <div style={{ color: "red" }}>{errorResume}</div>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Additional Document</label>
                <div id="file-upload-form" className="uploader">
                  <input
                    id="file-upload-doc"
                    type="file"
                    name="fileUpload"
                    accept=".pdf,.doc"
                    onChange={handleFileDocument}
                  />

                  <label for="file-upload-doc" id="file-drag">
                    {userUploadDoc}
                    <div id="start">
                      <span className="fasi">
                        <i className="fas fa-upload"></i>
                      </span>
                      <div>
                        {userUploadResume !== false
                          ? ""
                          : "Additional Document"}
                      </div>
                      <div id="notimage" className="hidden">
                        Please select document
                      </div>
                    </div>
                    <div id="response" className="hidden">
                      <div id="messages"></div>
                      <progress
                        className="progress"
                        id="file-progress"
                        value="0"
                      >
                        <span>0</span>%
                      </progress>
                    </div>
                    <div style={{ color: "red" }}>{errorDoc}</div>
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <button className="btn btn_man" onClick={submit} disabled={btn}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>

      <Footer />
    </>
  );
};

export default ApplyJobs;
