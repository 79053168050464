import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const WorldRanking = () => {
  return (
    <>
      <Navbar />
      <Sidebar />

      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: 'url("/assets/img/bg/wl-bg.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
               Ranking
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <h3 className="mblog__title mb-15">ATHLETES	RANKING	SYSTEM	</h3>
			<p>An	athlete	ranking	system	is	a	method	of	measuring	an	athlete's	performance	and	comparing	it	to	other	athletes	in	the	
same	sport.	These	rankings	are	used	by	sports	organiza;ons,	media	outlets,	and	fans	to	determine	an	athlete's	status	
within	the	sport	and	to	create	matchups	and	compe;;ons.	</p>

          <b>1.		World	Rankings:		</b>
			<p>These	rankings	take	into	account	an	athlete's	performance	in	major	compe;;ons	over	a	period	of	past	52	weeks.	The	
UKL	world	ranking	system	is	based	on	a	point	system	that	takes	into	account	a	fighter's	performance	in	both	na;onal	
and	interna;onal	compe;;ons.	The	more	a	fighter	wins,	the	more	points	they	earn,	and	the	higher	they	climb	up	the	
rankings.	 Points	 are	 awarded	 for	 wins	 against	 other	 ranked	 fighters,	 as	 well	 as	 for	 victories	 in	 major	 accredited	
interna;onal	compe;;ons	</p>
			<b>2.		Points-Based	Ranking:		</b>
			<p>UKL	uses	a	points-based	 ranking	 system,	where	athletes	earn	points	based	on	 their	performance	in	compe;;ons.	An	
athlete's	 score	 in	a	 compe;;on	 is	 converted	 into	a	 number	 of	 points,	and	 these	 points	are	 used	 to	 rank	 the	athlete	
against	others.	This	shall	be	used	in	domes;c	ranking.</p>
			<b>3.	RaDng	Percentage	Index	(RPI):	</b>
			<p>The	 RPI	 is	 a	 ranking	 system	 used	 in	 college	 sports	 in	 the	 United	 States.	 It	 takes	 into	 account	 a	 team's	 winning	
percentage,	the	strength	of	its	schedule,	and	the	winning	percentage	of	its	opponents	to	create	a	numerical	ra;ng.</p>
			
			<p>UKL	is	introducing	Athletes	Ranking	System	from	2024</p>
			
			
			
            <h4 className="mb-15">Phase-1 Teams</h4>
            <div className="row">
              <div className="col-lg-3 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/india.png" alt="india" />
                  <h5>Ranking </h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mb-3">
                <div className="marketng_box text-center">
                  <img src="/assets/img/flag/uae.png" alt="UAE" />
                  <h5>Domes:c Ranking </h5>
                </div>
              </div>
            </div>

            <h3 className="mblog__title mb-20 mt-50">SCHEDULE</h3>
            <div className="row">
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-users-medical"></i>
                  </span>
                  <h5>TEAM SELECTION </h5>
                  <ul>
                    <li>Selection Process</li>
                    <li>Timeline</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-globe"></i>
                  </span>
                  <h5>WORLD MATCHES </h5>
                  <ul>
                    <li>Schedule</li>
                    <li>Date</li>
                    <li>Place</li>
                    <li>Teams</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-clock"></i>
                  </span>
                  <h5>TELECAST TIMING</h5>
                  <ul>
                    <li>Telecast Timing</li>
                    <li>Channel details</li>
                    <li>Language</li>
                  </ul>
                </div>
              </div>
            </div>
            <h3 className="mblog__title mb-20 mt-50">ATHLETES</h3>
            <div className="row">
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-running"></i>
                  </span>
                  <h5>ATHLETES PROFILE</h5>
                  <ul>
                    <li>Selection Process</li>
                    <li>Timeline</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-gavel"></i>
                  </span>
                  <h5>ATHLETES AUCTION </h5>
                  <ul>
                    <li>Fixed Floor Price</li>
                    <li>Auction Model </li>
                    <li>Place of Auction </li>
                    <li>Teams Assigned </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-exchange"></i>
                  </span>
                  <h5>ATHLETES TRANSFER </h5>
                  <ul>
                    <li>Transfer Process</li>
                    <li>Talent Acquisition</li>
                    <li>Dispute Resolution</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default WorldRanking;
