import React from "react";
import Footer from "../../Common/Footer";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";

const UKLIndia = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: 'url("assets/img/bg/login.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                ULTIMATE KARATE LEAGUE
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <h6 className="mblog__title mb-15">UKL India</h6>
            <p className="mb-15"> UKL	India	is	a	professional	kabaddi	league	in	India	that	features	franchise	teams	from	different	parts	of	the	country.	As	of	now,	there	are	six	franchise	teams	compe@ng	in	the	league	under	the	
following	names:		1.	Bengaluru	Kings			2.	Delhi	Bravehearts.		3.	Mumbai	Ninjas.	4.	Pune	Divine		5.	Punjab	Fighters		6.	UP	Rebels	</p>
            <p className="mb-15"> The	league	has	been	gaining	popularity	over	the	years,	and	there	are	plans	to	expand	further.	According	to	reports,	UKL	India	is	likely	to	commission	two	new	teams	for	Season	3,	which	is	set	to	
begin	in	2023.	The	names	and	loca@ons	of	these	new	teams	are	yet	to	be	announced,	but	the	league	organizers	are	expected	to	make	an	official	announcement	soon.	This	expansion	will	bring	
more	excitement	to	the	league	and	provide	more	opportuni@es	for	players	and	fans	alike.</p>
            <div className="row ukl_indi_box">
              <div className="col-lg-2 col-md-4 mb-3">
                <a href="/punedivine" className="marketng_box text-center">
                  <img
                    src="/assets/img/pue_devi_small.png"
                    alt="India"
                    className="img-fluid"
                  />
                  <h5>PUNE DIVINE</h5>
                </a>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <a href="/uprebels" className="marketng_box text-center">
                  <img
                    src="/assets/img/up_rebls_small.png"
                    alt="UAE"
                    className="img-fluid"
                  />
                  <h5>UP REBELS</h5>
                </a>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <a href="/punjabfighters" className="marketng_box text-center">
                  <img
                    src="/assets/img/pf_small.png"
                    alt="Serbia"
                    className="img-fluid"
                  />
                  <h5>PUNJAB FIGHTERS</h5>
                </a>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <a href="/delhibravehearts" className="marketng_box text-center">
                  <img
                    src="/assets/img/delhi_bra_small.png"
                    alt="UK"
                    className="img-fluid"
                  />
                  <h5>DELHI BRAVEHEARTS</h5>
                </a>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <a href="/mumbaininjas" className="marketng_box text-center">
                  <img
                    src="/assets/img/mumbi_ninjay_small.png"
                    alt="Canada"
                    className="img-fluid"
                  />
                  <h5>MUMBAI NINJAS</h5>
                </a>
              </div>
              <div className="col-lg-2 col-md-4 mb-3">
                <a href="/bengalurukings" className="marketng_box text-center">
                  <img
                    src="/assets/img/bk.png"
                    alt="BENGALURU"
                    className="img-fluid"
                  />
                  <h5>BENGALURU KINGS</h5>
                </a>
              </div>
            </div>

            <h6 className="mblog__title mb-15 mt-md-5">SEASONS</h6>
            <div className="row">
              <div className="col-md-3 mb-4">
                <a href="pre-season" className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                  <h4>Pre-Season 2017</h4>
                </a>
              </div>
              <div className="col-md-3 mb-4">
                <a href="pre-season" className="marketng_box text-center">
                  <img
                    src="assets/img/seasons2.jpg"
                    alt="Project Sponsorship"
                    className="img-fluid"
                  />
                  <h4>2019 - SEASON 1.0</h4>
                </a>
              </div>
              <div className="col-md-3 mb-4">
                <a href="pre-season" className="marketng_box text-center">
                  <img
                    src="assets/img/seasons3.jpg"
                    alt="Team	Sales"
                    className="img-fluid"
                  />
                  <h4>2021 - SEASON 2.0</h4>
                </a>
              </div>
              <div className="col-md-3 mb-4">
                <a href="pre-season" className="marketng_box text-center">
                  <img
                    src="assets/img/seasons4.jpg"
                    alt="social_projects"
                    className="img-fluid"
                  />
                  <h4>2023 - SEASON 3.0</h4>
                </a>
              </div>
            </div>

            <h6 className="mblog__title mb-15 mt-md-5">ATHLETES</h6>
            <div className="row">
              <div href="" className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center cursor_pointer" data-bs-toggle="modal" data-bs-target="#myModal">
                  <span className="icon_box">
                    <i className="fas fa-running"></i>
                  </span>
                  <h5>ATHLETES PROFILE</h5>
                  <ul>
                    <li>Selection Process</li>
                    <li>Timeline</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-gavel"></i>
                  </span>
                  <h5>ATHLETES AUCTION </h5>
                  <ul>
                    <li>Fixed Floor Price</li>
                    <li>Auction Model </li>
                    <li>Place of Auction </li>
                    <li>Teams Assigned </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="rad_box text-center">
                  <span className="icon_box">
                    <i className="fas fa-exchange"></i>
                  </span>
                  <h5>ATHLETES TRANSFER </h5>
                  <ul>
                    <li>Transfer Process</li>
                    <li>Talent Acquisition</li>
                    <li>Dispute Resolution</li>
                  </ul>
                </div>
              </div>
            </div>
            <h6 className="mblog__title mb-15 mt-md-5">PRESS</h6>
            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/event_sponsorship.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                  <h4>PRESS</h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/project_sponsorship.jpg"
                    alt="Project Sponsorship"
                    className="img-fluid"
                  />
                  <h4>PRESS</h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/team_sales.jpg"
                    alt="Team	Sales"
                    className="img-fluid"
                  />
                  <h4>PRESS</h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/social_projects.jpg"
                    alt="social_projects"
                    className="img-fluid"
                  />
                  <h4>PRESS</h4>
                </div>
              </div>
            </div>

            <div className="mt-50">
              <h6 className="mblog__title mb-15">VIDEOS</h6>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/ZdlwdJAbJcI"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Athletes Training</h4>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/ZdlwdJAbJcI"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Coaches Training </h4>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="marketng_box text-center">
                    <iframe
                      style={{
                        width: "100%",
                      }}
                      height="200"
                      src="https://www.youtube.com/embed/ZdlwdJAbJcI"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4>Referees Training </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
	  
	  
	  
	  <div className="modal fade" id="myModal">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">

      <div className="modal-header">
        <h4 className="modal-title">Athletes Selection and Training</h4>
        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
      </div>

      <div className="modal-body">
       <p>The	selection	and	training	program	for	world-class	athletes	is	a	rigorous	and	complex	process	that	involves	a	range	of	factors	including	
physical	ability,	mental	toughness,	and	skill	development.	Here	are	some	key	components	of	a	successful	selection	and	training	program:	</p>
    

       <ul className="popop_list">
	     <li><b>Talent	Identification:</b>	The first step in selecting world-class athletes is identifying potential talent. This can be done through various methods such as scouting, testing, and assessments. Coaches and talent scouts are usually responsible for identifying athletes who have the potential to excel in their sport.</li>
	     <li><b>Physical	 Training:</b>	 Once selected, athletes undergo intensive physical training that includes strength and conditioning, agility and speed training, and endurance training. This is crucial for developing their physical abilities and preparing them for the demands of their sport.</li>
	     <li><b>Technical	 Training:</b>	 In addition to physical training, world-class athletes also undergo technical training to develop their skills and techniques. This may include specialized training for specific positions in team sports, or individual skill development for individual sports.</li>
	     <li><b>Mental	 Training:</b>	 Mental toughness and resilience are critical components of success for world-class athletes. Therefore, training programs should include mental training that helps athletes develop focus, concentration, and mental strength.	</li>
	     <li><b>Nutrition:</b>	     Proper nutrition is essential for athletes to perform at their best. A healthy and balanced diet is critical for optimal physical
performance, injury prevention, and recovery.</li>
	     <li><b>Medical	Support:</b>	To prevent and treat injuries, athletes need access to medical support. This may include physical therapy, sports medicine, and rehabilitation services.</li>
	     <li><b>Competition	and	Exposure:</b>	Competing at a high level is essential for world-class athletes to improve their skills and gain exposure to the sport. Participating in national and international competitions provides athletes with the opportunity to showcase their talents and gain recognition.</li>
	   </ul>
       <p>Overall, UKL selection and training program for world-class athletes sets a well-rounded approach that addresses physical, technical, mental, and nutritional needs. This comprehensive approach helps athletes develop their skills and abilities to their full potential, and prepares them for success at the highest level of competition.</p>
      </div>
    </div>
  </div>
</div>
      <Footer />
    </>
  );
};

export default UKLIndia;
